@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
*, ::before, ::after {
  border-color: currentColor
}

.tw-order-2 {
  order: 2
}

.tw-order-3 {
  order: 3
}

.tw-col-span-1 {
  grid-column: span 1 / span 1
}

.tw-col-span-2 {
  grid-column: span 2 / span 2
}

.tw-clear-both {
  clear: both
}

.tw-m-1 {
  margin: 0.25rem
}

.tw-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.tw-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.tw-my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.tw-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.tw-mt-0 {
  margin-top: 0px
}

.tw-mt-1 {
  margin-top: 0.25rem
}

.tw-mt-2 {
  margin-top: 0.5rem
}

.tw-mt-4 {
  margin-top: 1rem
}

.tw-mt-6 {
  margin-top: 1.5rem
}

.tw-mr-1 {
  margin-right: 0.25rem
}

.tw-mr-4 {
  margin-right: 1rem
}

.tw-mb-1 {
  margin-bottom: 0.25rem
}

.tw-mb-2 {
  margin-bottom: 0.5rem
}

.tw-mb-3 {
  margin-bottom: 0.75rem
}

.tw-mb-4 {
  margin-bottom: 1rem
}

.tw-mb-5 {
  margin-bottom: 1.25rem
}

.tw-mb-8 {
  margin-bottom: 2rem
}

.tw-ml-1 {
  margin-left: 0.25rem
}

.tw-ml-4 {
  margin-left: 1rem
}

.tw-block {
  display: block
}

.tw-flex {
  display: flex
}

.tw-inline-flex {
  display: inline-flex
}

.tw-grid {
  display: grid
}

.tw-h-full {
  height: 100%
}

.tw-w-4 {
  width: 1rem
}

.tw-w-64 {
  width: 16rem
}

.tw-w-full {
  width: 100%
}

.tw-flex-none {
  flex: none
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg)
  }
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.tw-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.tw-flex-col {
  flex-direction: column
}

.tw-flex-wrap {
  flex-wrap: wrap
}

.tw-content-center {
  align-content: center
}

.tw-items-start {
  align-items: flex-start
}

.tw-items-end {
  align-items: flex-end
}

.tw-items-center {
  align-items: center
}

.tw-items-stretch {
  align-items: stretch
}

.tw-justify-end {
  justify-content: flex-end
}

.tw-justify-center {
  justify-content: center
}

.tw-justify-between {
  justify-content: space-between
}

.tw-gap-1 {
  gap: 0.25rem
}

.tw-gap-2 {
  gap: 0.5rem
}

.tw-gap-3 {
  gap: 0.75rem
}

.tw-gap-4 {
  gap: 1rem
}

.tw-rounded {
  border-radius: 0.25rem
}

.tw-border {
  border-width: 1px
}

.tw-border-r-0 {
  border-right-width: 0px
}

.tw-border-b-0 {
  border-bottom-width: 0px
}

.tw-border-l-0 {
  border-left-width: 0px
}

.tw-border-solid {
  border-style: solid
}

.tw-border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(191, 208, 218, var(--tw-border-opacity))
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.hover\:tw-bg-lighterGrey:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 248, 249, var(--tw-bg-opacity))
}

.tw-p-1 {
  padding: 0.25rem
}

.tw-p-2 {
  padding: 0.5rem
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.tw-pt-3 {
  padding-top: 0.75rem
}

.tw-pt-4 {
  padding-top: 1rem
}

.tw-pl-3 {
  padding-left: 0.75rem
}

.tw-text-center {
  text-align: center
}

.tw-text-sm {
  font-size: 12px
}

.tw-text-md {
  font-size: 14px
}

.tw-text-lg {
  font-size: 18px
}

.tw-text-xl {
  font-size: 24px
}

.tw-font-semibold {
  font-weight: 600
}

.tw-text-black {
  color: rgba(0,0,0,0.87)
}

.tw-text-darkGrey {
  --tw-text-opacity: 1;
  color: rgba(124, 135, 142, var(--tw-text-opacity))
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

@media (min-width: 640px) {
  .sm\:tw-order-1 {
    order: 1
  }

  .sm\:tw-flex {
    display: flex
  }
}

@media (min-width: 768px) {
  .md\:tw-order-2 {
    order: 2
  }

  .md\:tw-order-3 {
    order: 3
  }

  .md\:tw-col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:tw-hidden {
    display: none
  }

  .md\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:tw-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .md\:tw-text-lg {
    font-size: 18px
  }
}

@media (min-width: 992px) {
  .lg\:tw-mb-0 {
    margin-bottom: 0px
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1536px) {
}

/* purgecss start ignore */

/* App */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f8f9;
  color: #404040;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
}

body,
div,
p,
span,
input {
  font-size: 12px;
}

body,
div,
p,
span,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif !important;
}

h2 {
  font-weight: 300;
}

h3 {
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  h3 {
    font-size: 14px;
  }
}

a {
  color: #4c9eff;
  text-decoration: none !important;
}

small {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  small {
    font-size: 10px;
  }
}

.App {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .App {
    overflow-x: hidden;
  }
}

.container {
  padding-top: 20px;
  padding-bottom: 100px;
  z-index: 25500;
}

@media (max-width: 767px) {
  .container {
    padding-top: 30px;
    padding-bottom: 110px;
  }
}

button {
  border-radius: 3px !important;
}

button span {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Login input[type='text']::-ms-clear,
.Login input[type='password']::-ms-reveal {
  display: none !important;
}

.main-button {
  border: 1px solid #4c9eff !important;
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  color: #479cff !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  text-align: center !important;
  padding: 7px 9px 10px !important;
  height: auto !important;
}

.main-button:hover {
  background-color: #4c9eff !important;
  color: #fff !important;
}

.main-button[disabled] {
  background-color: transparent !important;
  border: solid 1px #7c878e !important;
  color: #7c878e !important;
}

.message-danger > div {
  background: rgba(211, 47, 47, 0.87) !important;
}

.Dialog > div > div {
  max-width: 428px !important;
}

.Dialog > div > div .Dialog-contents {
  padding: 0px 24px !important;
}

.Dialog > div > div .Dialog-actions {
  padding: 16px 0px 24px 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/* Cards */

.Material-cards__collapsed,
.Pipeline-cards__collapsed {
  font-family: 'Open Sans', sans-serif !important;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .Material-cards__collapsed thead th:first-child,
  .Material-cards__collapsed thead td:first-child,
  .Material-cards__collapsed tbody th:first-child,
  .Material-cards__collapsed tbody td:first-child,
  .Pipeline-cards__collapsed thead th:first-child,
  .Pipeline-cards__collapsed thead td:first-child,
  .Pipeline-cards__collapsed tbody th:first-child,
  .Pipeline-cards__collapsed tbody td:first-child {
    font-weight: 600;
    width: 36%;
  }

  .Material-cards__collapsed thead th:nth-child(2),
  .Material-cards__collapsed thead td:nth-child(2),
  .Material-cards__collapsed tbody th:nth-child(2),
  .Material-cards__collapsed tbody td:nth-child(2),
  .Pipeline-cards__collapsed thead th:nth-child(2),
  .Pipeline-cards__collapsed thead td:nth-child(2),
  .Pipeline-cards__collapsed tbody th:nth-child(2),
  .Pipeline-cards__collapsed tbody td:nth-child(2) {
    width: 12%;
    text-align: right !important;
  }

  .Material-cards__collapsed thead th:nth-child(5),
  .Material-cards__collapsed thead td:nth-child(5),
  .Material-cards__collapsed tbody th:nth-child(5),
  .Material-cards__collapsed tbody td:nth-child(5),
  .Pipeline-cards__collapsed thead th:nth-child(5),
  .Pipeline-cards__collapsed thead td:nth-child(5),
  .Pipeline-cards__collapsed tbody th:nth-child(5),
  .Pipeline-cards__collapsed tbody td:nth-child(5) {
    padding-right: 0 !important;
  }

  .Material-cards__collapsed thead th:nth-child(5) .Material-cards-stages-wrapper button,
  .Material-cards__collapsed thead td:nth-child(5) .Material-cards-stages-wrapper button,
  .Material-cards__collapsed tbody th:nth-child(5) .Material-cards-stages-wrapper button,
  .Material-cards__collapsed tbody td:nth-child(5) .Material-cards-stages-wrapper button,
  .Pipeline-cards__collapsed thead th:nth-child(5) .Material-cards-stages-wrapper button,
  .Pipeline-cards__collapsed thead td:nth-child(5) .Material-cards-stages-wrapper button,
  .Pipeline-cards__collapsed tbody th:nth-child(5) .Material-cards-stages-wrapper button,
  .Pipeline-cards__collapsed tbody td:nth-child(5) .Material-cards-stages-wrapper button {
    float: right;
  }

  .Material-cards__collapsed thead th:nth-child(3),
  .Material-cards__collapsed thead th:nth-child(4),
  .Material-cards__collapsed thead th:nth-child(5),
  .Material-cards__collapsed thead td:nth-child(3),
  .Material-cards__collapsed thead td:nth-child(4),
  .Material-cards__collapsed thead td:nth-child(5),
  .Material-cards__collapsed tbody th:nth-child(3),
  .Material-cards__collapsed tbody th:nth-child(4),
  .Material-cards__collapsed tbody th:nth-child(5),
  .Material-cards__collapsed tbody td:nth-child(3),
  .Material-cards__collapsed tbody td:nth-child(4),
  .Material-cards__collapsed tbody td:nth-child(5),
  .Pipeline-cards__collapsed thead th:nth-child(3),
  .Pipeline-cards__collapsed thead th:nth-child(4),
  .Pipeline-cards__collapsed thead th:nth-child(5),
  .Pipeline-cards__collapsed thead td:nth-child(3),
  .Pipeline-cards__collapsed thead td:nth-child(4),
  .Pipeline-cards__collapsed thead td:nth-child(5),
  .Pipeline-cards__collapsed tbody th:nth-child(3),
  .Pipeline-cards__collapsed tbody th:nth-child(4),
  .Pipeline-cards__collapsed tbody th:nth-child(5),
  .Pipeline-cards__collapsed tbody td:nth-child(3),
  .Pipeline-cards__collapsed tbody td:nth-child(4),
  .Pipeline-cards__collapsed tbody td:nth-child(5) {
    width: 16%;
  }

  .Material-cards__collapsed thead th:nth-child(3):not(:nth-child(5)),
  .Material-cards__collapsed thead th:nth-child(4):not(:nth-child(5)),
  .Material-cards__collapsed thead th:nth-child(5):not(:nth-child(5)),
  .Material-cards__collapsed thead td:nth-child(3):not(:nth-child(5)),
  .Material-cards__collapsed thead td:nth-child(4):not(:nth-child(5)),
  .Material-cards__collapsed thead td:nth-child(5):not(:nth-child(5)),
  .Material-cards__collapsed tbody th:nth-child(3):not(:nth-child(5)),
  .Material-cards__collapsed tbody th:nth-child(4):not(:nth-child(5)),
  .Material-cards__collapsed tbody th:nth-child(5):not(:nth-child(5)),
  .Material-cards__collapsed tbody td:nth-child(3):not(:nth-child(5)),
  .Material-cards__collapsed tbody td:nth-child(4):not(:nth-child(5)),
  .Material-cards__collapsed tbody td:nth-child(5):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead th:nth-child(3):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead th:nth-child(4):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead th:nth-child(5):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead td:nth-child(3):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead td:nth-child(4):not(:nth-child(5)),
  .Pipeline-cards__collapsed thead td:nth-child(5):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody th:nth-child(3):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody th:nth-child(4):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody th:nth-child(5):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody td:nth-child(3):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody td:nth-child(4):not(:nth-child(5)),
  .Pipeline-cards__collapsed tbody td:nth-child(5):not(:nth-child(5)) {
    text-align: right !important;
  }

  .Material-cards__collapsed thead th:last-child,
  .Material-cards__collapsed thead td:last-child,
  .Material-cards__collapsed tbody th:last-child,
  .Material-cards__collapsed tbody td:last-child,
  .Pipeline-cards__collapsed thead th:last-child,
  .Pipeline-cards__collapsed thead td:last-child,
  .Pipeline-cards__collapsed tbody th:last-child,
  .Pipeline-cards__collapsed tbody td:last-child {
    width: 5%;
    text-align: right !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .Material-cards__collapsed thead th:last-child .Material-cards__expanded-actions,
  .Material-cards__collapsed thead td:last-child .Material-cards__expanded-actions,
  .Material-cards__collapsed tbody th:last-child .Material-cards__expanded-actions,
  .Material-cards__collapsed tbody td:last-child .Material-cards__expanded-actions,
  .Pipeline-cards__collapsed thead th:last-child .Material-cards__expanded-actions,
  .Pipeline-cards__collapsed thead td:last-child .Material-cards__expanded-actions,
  .Pipeline-cards__collapsed tbody th:last-child .Material-cards__expanded-actions,
  .Pipeline-cards__collapsed tbody td:last-child .Material-cards__expanded-actions {
    margin-right: 0 !important;
  }
}

.Material-cards__collapsed thead th,
.Pipeline-cards__collapsed thead th {
  background-color: #f5f8f9 !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  height: 30px !important;
  color: #7c878e !important;
  white-space: initial !important;
}

.Material-cards__collapsed thead th:first-child,
.Pipeline-cards__collapsed thead th:first-child {
  padding-left: 20px !important;
}

.Material-cards__collapsed tbody tr.red,
.Pipeline-cards__collapsed tbody tr.red {
  border-left: solid 5px #d0021b;
}

.Material-cards__collapsed tbody tr.red td:nth-child(3),
.Pipeline-cards__collapsed tbody tr.red td:nth-child(3) {
  color: #d0021b;
}

.Material-cards__collapsed tbody tr.yellow,
.Pipeline-cards__collapsed tbody tr.yellow {
  border-left: solid 5px #ffc72c;
}

.Material-cards__collapsed tbody tr.yellow td:nth-child(3),
.Pipeline-cards__collapsed tbody tr.yellow td:nth-child(3) {
  color: #ffc72c;
}

.Material-cards__collapsed tbody tr.green,
.Pipeline-cards__collapsed tbody tr.green {
  border-left: solid 5px #417505;
}

.Material-cards__collapsed tbody tr.green td:nth-child(3),
.Pipeline-cards__collapsed tbody tr.green td:nth-child(3) {
  color: #417505;
}

.Material-cards__collapsed tbody tr.gray,
.Pipeline-cards__collapsed tbody tr.gray {
  border-left: solid 5px #bfd0da;
}

.Material-cards__collapsed tbody tr.gray td:nth-child(3),
.Pipeline-cards__collapsed tbody tr.gray td:nth-child(3) {
  color: #bfd0da;
}

.Material-cards__collapsed tbody td,
.Pipeline-cards__collapsed tbody td {
  font-size: 12px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.Material-cards__collapsed tbody td a,
.Pipeline-cards__collapsed tbody td a {
  color: #404040 !important;
}

.Material-cards__collapsed tbody td:nth-child(5) span,
.Pipeline-cards__collapsed tbody td:nth-child(5) span {
  padding-right: 0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Material-cards__collapsed tbody td .Material-cards__expanded-actions,
.Pipeline-cards__collapsed tbody td .Material-cards__expanded-actions {
  display: block !important;
}

.Material-cards__collapsed tbody td .Material-cards__expanded-state,
.Pipeline-cards__collapsed tbody td .Material-cards__expanded-state {
  border: none !important;
}

.Material-cards__collapsed tbody td .Material-cards__expanded-state.add,
.Pipeline-cards__collapsed tbody td .Material-cards__expanded-state.add {
  color: #479cff !important;
  margin-right: 0 !important;
  background-color: transparent !important;
  border: none !important;
}

.Material-cards__collapsed tbody td .Material-cards__expanded-state.add.action,
.Pipeline-cards__collapsed tbody td .Material-cards__expanded-state.add.action {
  color: #33383b !important;
}

.Material-cards__collapsed tbody td .Material-cards__expanded-state.add svg,
.Pipeline-cards__collapsed tbody td .Material-cards__expanded-state.add svg {
  color: #7c878e !important;
}

.Material-cards {
  display: block;
  width: 100%;
}

.Material-cards h3 {
  font-weight: 600;
  margin: -3px 0 13px;
  cursor: pointer;
}

.Material-cards h3 a {
  color: #404040;
}

@media (max-width: 767px) {
  .Material-cards h3 {
    margin-bottom: 10px !important;
    padding-right: 10px;
  }
}

.Material-cards small {
  color: #7c878e;
}

.Material-cards svg {
  color: #7c878e !important;
  position: relative;
  top: 8px;
}

.Material-cards__expanded {
  margin: 6px 0;
  padding: 20px;
  position: relative;
}

@media (max-width: 767px) {
  .Material-cards__expanded {
    height: auto !important;
    margin: 0 3px 6px;
    padding: 12px 15px;
  }
}

.Material-cards__expanded-actions {
  position: relative !important;
  margin-right: -15px !important;
}

.Material-cards__expanded-actions svg {
  top: 0;
  color: #7c878e !important;
}

@media (max-width: 767px) {
  .Material-cards__expanded-actions {
    position: absolute !important;
    right: 10px;
    top: 10px;
  }
}

@media (min-width: 768px) {
  .Material-cards__expanded-actions-wrapper {
    float: right;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Material-cards__expanded-actions-wrapper {
    flex-wrap: wrap;
  }
}

.Material-cards__expanded-actions-wrapper .Material-cards-stages-wrapper {
  display: inline-block;
}

.Material-cards__expanded-typical-gift {
  display: inline-block;
  vertical-align: top;
}

.Material-cards__expanded-typical-gift strong {
  color: #33383b;
  font-weight: 600 !important;
  padding-right: 20px;
}

.fr .Material-cards__expanded-typical-gift strong {
  padding-right: 10px;
}

.Material-cards__expanded-state {
  border: solid 1px #bfd0da !important;
  line-height: 34px !important;
  padding-right: 10px !important;
}

.Material-cards__expanded-state svg {
  top: 0 !important;
  margin-right: -5px !important;
}

.Material-cards__expanded-state.add {
  background-color: #4c9eff !important;
  border: solid 1px #4c9eff !important;
  color: #fff !important;
  padding-right: 0 !important;
}

.Material-cards__expanded-state.add:hover {
  background-color: #3b7bc8 !important;
}

.Material-cards__expanded-state.add[disabled] {
  background-color: transparent !important;
  border: solid 1px #7c878e !important;
  color: #7c878e !important;
}

.Material-cards__expanded-state.action {
  border: solid 1px #bfd0da !important;
}

.Material-cards__expanded-state.action:hover {
  background-color: #bfd0da !important;
}

@media (max-width: 767px) {
  .Material-cards__expanded-state {
    display: none !important;
  }
}

.Material-cards__expanded-status {
  padding-bottom: 8px;
}

.Material-cards__expanded-status small {
  padding-left: 15px;
}

@media (min-width: 768px) {
  .Material-cards__expanded-status {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .Material-cards__expanded-status {
    display: none;
  }
}

@media (min-width: 992px) {
  .Material-cards__expanded-deadline {
    display: inline-block;
    float: right;
  }
}

.Material-cards__expanded-deadline small {
  font-weight: 600;
}

.Material-cards__expanded-deadline small svg {
  margin-right: 3px;
}

@media (max-width: 767px) {
  .Material-cards__expanded-deadline small svg {
    width: 19px !important;
  }
}

.Material-cards__expanded-deadline.red small,
.Material-cards__expanded-deadline.red svg {
  color: #d0021b !important;
}

.Material-cards__expanded-deadline.green small,
.Material-cards__expanded-deadline.green svg {
  color: #417505 !important;
}

.Material-cards__expanded-deadline.yellow small,
.Material-cards__expanded-deadline.yellow svg {
  color: #ffc72c !important;
}

.Material-cards__expanded-deadline.gray small,
.Material-cards__expanded-deadline.gray svg {
  color: #7c878e !important;
}

.Material-cards__expanded-teaser {
  margin-bottom: 2px;
  line-height: 20px;
}

@media (min-width: 992px) {
  .Material-cards__expanded-teaser {
    max-width: 400px;
  }
}

@media (max-width: 767px) {
  .Material-cards__expanded-teaser {
    display: none;
  }
}

.Material-cards__expanded-notification {
  display: inline-block;
  margin-right: 10px;
}

.Material-cards__expanded-notification small {
  color: #33383b !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .Material-cards__expanded-notification {
    display: none;
  }
}

.Material-cards__expanded-location {
  display: inline-block;
}

.Material-cards__expanded.red {
  border-left: solid 5px #d0021b;
}

.Material-cards__expanded.yellow {
  border-left: solid 5px #ffc72c;
}

.Material-cards__expanded.green {
  border-left: solid 5px #417505;
}

.Material-cards__expanded.gray {
  border-left: solid 5px #bfd0da;
}

.Material-cards__expanded-right {
  text-align: right;
}

@media (max-width: 767px) {
  .Material-cards__expanded-right {
    margin-top: 15px;
    text-align: left;
  }
}

.fr .Material-cards__expanded-right button span {
  white-space: nowrap !important;
  font-size: 10px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.Full-card {
  padding: 20px 15px 15px 15px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .Full-card {
    margin: -15px 0 15px 0;
  }
}

.Full-card.red {
  border-left: solid 5px #d0021b;
}

.Full-card.yellow {
  border-left: solid 5px #ffc72c;
}

.Full-card.green {
  border-left: solid 5px #417505;
}

.Full-card.gray {
  border-left: solid 5px #bfd0da;
}

.Full-card h1 {
  font-weight: 600 !important;
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .Full-card h1 {
    font-size: 18px;
  }
}

@media (max-width: 1199px) {
  .Full-card h1 {
    margin-bottom: 0;
    width: 95%;
  }
}

.Full-card .col-xl-4 > div {
  text-align: right;
}

.Full-card__type {
  padding-top: 13px;
  display: inline-block;
}

.Full-card__website {
  margin: 3px 0 0 20px !important;
}

@media (max-width: 767px) {
  .Full-card__website {
    margin-top: 0 !important;
  }
}

.Full-card__website span {
  color: #4c9eff;
}

.Full-card__website:hover {
  background-color: transparent !important;
}

.Full-card__teaser {
  line-height: 18px;
  padding: 0 40px 20px 8px;
}

.Full-card__values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Full-card__values small {
  font-weight: 600 !important;
  padding-bottom: 6px;
  display: block;
}

.Full-card__values big {
  font-size: 24px;
}

@media (max-width: 767px) {
  .Full-card__values big {
    font-size: 18px;
  }
}

.Full-card__values-gifts,
.Full-card__values-typical-gift {
  margin: 0 25px 10px 0;
}

@media (max-width: 991px) {
  .Full-card__values-gifts,
  .Full-card__values-typical-gift {
    margin-right: 15px;
  }
}

.Full-card__deadline.red small,
.Full-card__deadline.red svg {
  color: #d0021b !important;
}

.Full-card__deadline.green small,
.Full-card__deadline.green svg {
  color: #417505 !important;
}

.Full-card__deadline.yellow small,
.Full-card__deadline.yellow svg {
  color: #ffc72c !important;
}

.Full-card__deadline.gray small,
.Full-card__deadline.gray svg {
  color: #7c878e !important;
}

.Full-card__deadline svg,
.Full-card__open-to-requests svg {
  color: #7c878e !important;
  width: 22px !important;
  height: 22px !important;
  padding-right: 5px;
}

.Full-card__deadline small,
.Full-card__open-to-requests small {
  vertical-align: super;
  font-weight: 600 !important;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .Full-card__deadline small:empty {
    display: none;
  }
}

@media (max-width: 991px) {
  .Full-card__actions {
    margin: 15px 0;
  }
}

@media (min-width: 992px) {
  .Full-card__actions {
    position: absolute;
    right: 5px;
  }
}

.Full-card__request-size {
  display: inline-block;
  background: #fff;
}

.Full-card__request-size small {
  margin-right: 3px;
}

.Full-card__request-size div small {
  vertical-align: text-top;
}

.Full-card__request-size input {
  width: 70px !important;
  border: 0 !important;
  border-bottom: solid 1px #bfd0da !important;
  margin-right: 15px;
  color: #7c878e !important;
  outline: none;
}

.Full-card__request-size .Full-card__request-size-edit {
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
  margin: 0 18px 12px 10px !important;
}

.Full-card__request-size .Full-card__request-size-edit svg {
  color: #7c878e !important;
  width: 18px !important;
  height: 18px !important;
  vertical-align: bottom;
}

.Full-card__request-size .Full-card__request-size-edit svg:hover {
  color: #33383b !important;
}

.Full-card__request-size .Full-card__status {
  min-width: 49px !important;
  background: #bfd0da !important;
  height: 32px !important;
  line-height: 32px !important;
}

.Full-card__request-size .Full-card__status:last-child {
  color: #7c878e !important;
  border: 0 !important;
  background: none !important;
}

.Full-card__request-size .Full-card__status span {
  padding: 10px !important;
}

.Full-card__status {
  border: solid 1px #bfd0da !important;
}

.Full-card__status.add {
  background-color: #4c9eff !important;
  border: solid 1px #4c9eff !important;
  color: #fff !important;
}

.Full-card__status.add:hover {
  background-color: #3b7bc8 !important;
}

.Pipeline-cards__collapsed .Full-card__status.add {
  background-color: transparent !important;
  border: none !important;
  color: #4c9eff !important;
}

.Full-card .Material-cards-stages-wrapper {
  display: inline-block;
}

.Full-card .Material-cards-actions-wrapper {
  margin: -5px 10px 0 0;
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .Full-card .Material-cards-actions-wrapper {
    position: absolute;
    right: 10px;
    top: 15px !important;
  }
}

@media (max-width: 991px) {
  .Full-card .Material-cards-actions-wrapper {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

/* Left Menu */

.Left-menu {
  position: absolute;
  z-index: 10;
}

@media (max-width: 767px) {
  .Left-menu {
    z-index: 30000 !important;
  }
}

.Left-menu__wrapper {
  background-color: #33383b !important;
  padding: 100px 35px;
}

@media (max-width: 767px) {
  .Left-menu__wrapper {
    padding: 30px 20px;
    width: 256px !important;
  }
}

.Left-menu__wrapper button {
  position: absolute !important;
  right: 0;
  top: 65px;
}

@media (max-width: 767px) {
  .Left-menu__wrapper button {
    top: 0;
  }
}

.Left-menu__wrapper button svg {
  color: #d9e3e9 !important;
  height: 18px !important;
  width: 18px !important;
}

.Left-menu__wrapper span [role='menuitem'] {
  background: none !important;
}

.Left-menu__wrapper a {
  color: #d9e3e9;
  font-size: 14px;
  margin-left: -5px;
}

.Left-menu__wrapper hr {
  background-color: #7c878e;
  border: 0;
  height: 1px;
  margin: 10px 0 10px 5px;
  text-align: left;
  width: 80%;
}

@media (max-width: 767px) {
  .Left-menu__wrapper hr {
    width: 85%;
  }
}

/* Header */

.App-header {
  position: relative;
}

.Header-wrapper {
  background-color: #ffc72c !important;
  height: 60px;
}

@media (max-width: 767px) {
  .Header-wrapper {
    z-index: 0 !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
    z-index: 15000 !important;
  }

  .Header-wrapper > div:nth-child(2) {
    margin-left: -6px !important;
  }
}

.Header-wrapper [data-class='Header-help'],
.Header-wrapper .Header-icon,
.Header-wrapper .Header-icon-account {
  fill: #806416 !important;
  margin-top: -3px;
}

.Header-wrapper [data-class='Header-help']:hover,
.Header-wrapper .Header-icon:hover,
.Header-wrapper .Header-icon-account:hover {
  fill: #402701 !important;
}

@media (max-width: 767px) {
  .Header-wrapper .Header-icon-account {
    display: none !important;
  }
}

.Header-wrapper [data-class='Header-help'] {
  margin-right: 30px;
}

@media (max-width: 991px) {
  .Header-wrapper [data-class='Header-help'] {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .Header-wrapper [data-class='Header-help'] {
    display: none !important;
  }
}

.Header-wrapper .Header-button {
  right: 10px;
  top: -9px;
  background-color: rgba(0, 0, 0, 0) !important;
  min-width: auto !important;
  overflow: visible !important;
  line-height: 36px !important;
  height: 36px !important;
  border: none !important;
}

@media (max-width: 991px) {
  .Header-wrapper .Header-button {
    right: -5px;
  }
}

@media (max-width: 767px) {
  .Header-wrapper .Header-button {
    display: none !important;
  }
}

.Header-wrapper .Header-button span {
  font-weight: 700 !important;
  color: #806416;
  overflow: visible !important;
  padding: 0 !important;
}

.Header-wrapper .Header-button:hover span {
  color: #402701;
}

.Header-wrapper .App-logo {
  position: relative;
  top: 30%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .Header-wrapper .App-logo {
    width: 117px;
  }
}

@media (min-width: 768px) {
  .Header-wrapper .App-logo {
    margin-left: 10px;
  }
}

.Header-popover {
  width: 135px;
  background-color: #7c878e !important;
}

.Header-popover div {
  color: #d9e3e9;
}

.Header-popover div [role='menu'] {
  padding: 5px 0;
  background-color: #33383b;
}

.Header-popover div [role='menuitem'] {
  line-height: 24px !important;
  min-height: 24px !important;
  background-color: #33383b !important;
}

.Header-popover div [role='menuitem']:hover {
  background-color: #7c878e !important;
  opacity: 1;
}

.Header-popover div [role='presentation'] {
  width: 135px !important;
}

.Header-search {
  align-items: center;
  display: flex;
  height: 60px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 60%;
  z-index: 1200;
}

@media screen and (max-width: 767px) {
  .Header-search {
    justify-content: left;
  }
}

@media screen and (min-width: 768px) {
  .Header-search {
    justify-content: center;
  }
}

@media (max-width: 1399px) {
  .Header-search {
    left: 57.5%;
  }
}

.Header-search .Header-search-field-container {
  height: 40px;
  margin: 0 2px;
  position: relative;
  width: 70%;
  display: inline-block;
  vertical-align: top;
}

.Header-search__autocomplete {
  height: 40px !important;
  line-height: 18px !important;
}

.Header-search__autocomplete-menu-list {
  padding-bottom: 0 !important;
}

.Header-search__autocomplete-menu-list > div > span > div > span {
  top: -10px !important;
}

.Header-search__autocomplete-menu-list div {
  position: relative !important;
}

.Header-search__autocomplete-menu-list div span {
  white-space: normal !important;
  line-height: 1.6 !important;
  min-height: auto !important;
  padding: 10px 0 !important;
}

.Header-search__autocomplete-menu-list div span i {
  margin-left: 5px;
}

.Header-search__autocomplete-menu-list div span.last-taxonomy-in-list {
  margin-bottom: 40px !important;
}

.Header-search__autocomplete-menu-list div span.last-taxonomy-in-list::before {
  content: '';
  position: absolute;
  bottom: -10px;
  height: 2px;
  background: #7c878e;
  width: calc(100% - 40px);
  left: 20px;
}

.Header-search__autocomplete-menu-list div span.last-taxonomy-in-list::after {
  content: 'Profiles';
  position: absolute;
  bottom: -40px;
  width: auto;
  left: 15px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  color: #7c878e;
}

.fr .Header-search__autocomplete-menu-list div span.last-taxonomy-in-list::after {
  content: 'Profils';
}

.Header-search__autocomplete-menu-list div span.last-taxonomy-in-list > div > div span {
  display: inline-block;
  padding: 0 !important;
  min-width: 100%;
}

.Header-search__autocomplete-menu-list div span.extra {
  display: inline-block !important;
  width: 100%;
  background: #b1c5d4 !important;
  border: 1px solid #99abb9 !important;
}

.Header-search__autocomplete-menu-list div span.extra:hover {
  background: #99abb9 !important;
}

.Header-search__autocomplete-menu-list div span.extra > div > div {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.Header-search__autocomplete-menu-list div span.extra .extra--text {
  font-size: 10px !important;
  order: 1;
}

.Header-search__autocomplete-menu-list div span.extra .extra--text div {
  font-size: 10px !important;
}

.Header-search__autocomplete-menu-list div span.extra .extra--item {
  order: 2;
  background: #7c878e;
  color: white;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  padding: 5px;
}

.Header-search__autocomplete div {
  color: #33383b !important;
  padding-left: 15px;
}

.Header-search__autocomplete input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
}

@media (min-width: 768px) {
  .Header-search__autocomplete input {
    text-indent: 15px;
  }
}

.Header-search__autocomplete input::ms-clear {
  display: none;
}

.Header-search__submit {
  background-color: #806416 !important;
  border-radius: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
  margin-left: 2px !important;
  min-width: 40px !important;
}

.Header-search__submit-icon {
  fill: #ffe9ac !important;
}

.Header-search__submit:hover {
  background-color: #402701 !important;
}

@media (min-width: 768px) {
  .Header-search__submit svg {
    height: 21px !important;
    width: 21px !important;
  }
}

.__react_component_tooltip.show {
  border-radius: 0 !important;
  font-size: 12px !important;
  min-width: 65px;
  position: absolute !important;
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .Header-search {
    width: 100% !important;
    left: 50% !important;
    z-index: 25000;
  }

  .Header-search > button:first-child {
    background-color: #fff !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 0 !important;
    z-index: 10;
    border-left: solid 10px #ffc72c !important;
  }

  .Header-search > button:first-child svg {
    margin-top: -5px;
    margin-left: -5px;
  }

  .Header-search__autocomplete {
    background-color: #fff !important;
    border-left: solid 1px #fff;
  }

  .Header-search__autocomplete div {
    padding-left: 50px !important;
    color: #bfd0da !important;
  }

  .Header-search__autocomplete input {
    padding-left: 50px !important;
  }

  .Header-search-mobile-field-container {
    width: 100%;
    position: absolute;
  }

  .Header-search__open-search {
    position: absolute !important;
    right: 8px;
    top: 6px;
    z-index: 20000;
  }

  .Header-search__open-search svg {
    color: #806416 !important;
  }

  .Header-search__submit {
    background-color: #fff !important;
    border: 0 !important;
    overflow: visible !important;
    line-height: 0px !important;
    position: absolute !important;
    z-index: 11000 !important;
    right: 0;
    border-right: solid 10px #ffc72c !important;
    z-index: 20000 !important;
  }

  .Header-search__submit:hover,
  .Header-search__submit:focus {
    background-color: #fff !important;
  }

  .Header-search__submit-icon {
    height: 24px !important;
    width: 24px !important;
    fill: #7c878e !important;
    padding-right: 10px;
  }

  .Header-search__submit span {
    display: none;
  }
}

@media (max-width: 767px) {
  div[style='position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; z-index: 2000;'] {
    z-index: 25000 !important;
  }
}

/* Loading Component */

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */

.spinner {
  margin: 0;
  width: 25px;
  text-align: left;
}

.spinner > div {
  width: 4px;
  height: 4px;
  background-color: #333;
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/* Nested Dropdown */

.Header-search__select {
  background-color: #ffd86c !important;
  padding: 0 !important;
}

@media (min-width: 1400px) {
  .Header-search__select-item {
    line-height: 8px !important;
  }
}

.Header-search__select-item button {
  height: 40px !important;
  padding: 8px !important;
  width: 38px !important;
}

@media (max-width: 1399px) {
  .Header-search__select-item div div {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }

  .Header-search__select-item div div div {
    padding: 0 !important;
    word-wrap: break-word;
  }
}

.Header-search .Header-search__select-item ~ div .Header-search__select-item div div button {
  padding: 12px 8px !important;
}

.Header-search__autocomplete,
.Header-search__select {
  font-size: 12px !important;
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
}

@media (min-width: 768px) {
  .Header-search__autocomplete,
  .Header-search__select {
    background-color: #ffd86c !important;
  }
}

.Header-search__autocomplete.highlight,
.Header-search__select.highlight {
  background-color: #fff !important;
  padding: 0 !important;
}

.Header-search__autocomplete.highlight div [style='padding: 0px;'],
.Header-search__select.highlight div [style='padding: 0px;'] {
  border-top: solid 1px #bfd0da !important;
}

.Header-search__autocomplete.highlight div [style='padding: 0px;'] .Header-search__select-item,
.Header-search__select.highlight div [style='padding: 0px;'] .Header-search__select-item {
  border: solid 1px #bfd0da !important;
  border-top: 0 !important;
  line-height: 14px !important;
  float: left;
  width: 100%;
  background-color: #fff !important;
}

@media (max-width: 1399px) {
  .Header-search__autocomplete.highlight div [style='padding: 0px;'] .Header-search__select-item,
  .Header-search__select.highlight div [style='padding: 0px;'] .Header-search__select-item {
    float: right;
    width: 230px;
  }
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  .Header-search__select-item:hover,
.Header-search__select.highlight div [style='padding: 0px;'] .Header-search__select-item:hover {
  background-color: #f5f8f9 !important;
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  .Header-search__select-item
  div
  div,
.Header-search__select.highlight div [style='padding: 0px;'] .Header-search__select-item div div {
  padding-left: 0 !important;
  padding-right: 25px !important;
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  .Header-search__select-item
  div
  div
  svg,
.Header-search__select.highlight
  div
  [style='padding: 0px;']
  .Header-search__select-item
  div
  div
  svg {
  margin-right: 6px !important;
}

.Header-search__autocomplete.highlight div [style='padding: 0px;'] div [style='padding: 0px;'],
.Header-search__select.highlight div [style='padding: 0px;'] div [style='padding: 0px;'] {
  background-color: #f5f8f9;
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item,
.Header-search__select.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item {
  background-color: #f5f8f9 !important;
  border-top: 0 !important;
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item:hover,
.Header-search__select.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item:hover {
  background-color: #d9e3e9 !important;
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item
  div
  div,
.Header-search__select.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item
  div
  div {
  padding-left: 0px !important;
}

@media (min-width: 1400px) {
  .Header-search__autocomplete.highlight
    div
    [style='padding: 0px;']
    div
    [style='padding: 0px;']
    .Header-search__select-item
    button,
  .Header-search__select.highlight
    div
    [style='padding: 0px;']
    div
    [style='padding: 0px;']
    .Header-search__select-item
    button {
    top: 5px !important;
  }
}

.Header-search__autocomplete.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item
  button
  svg,
.Header-search__select.highlight
  div
  [style='padding: 0px;']
  div
  [style='padding: 0px;']
  .Header-search__select-item
  button
  svg {
  color: #7c878e !important;
  height: 18px !important;
  width: 18px !important;
}

.Header-search__autocomplete input,
.Header-search__select input {
  text-transform: none;
}

.Header-search__select-item .Header-search__select-item-flex {
  display: flex;
  justify-content: space-between;
}

.Header-search__select-item .Header-search__select-item-flex svg {
  height: 16px !important;
  fill: #7c878e !important;
}

/* Pagination */

table {
  font-family: 'Open Sans', sans-serif !important;
}

table tr th,
table tr td {
  font-size: 12px !important;
}

@media (max-width: 767px) {
  table tr th,
  table tr td {
    font-size: 10px !important;
  }
}

.pagination__wrapper {
  width: 100%;
  margin-top: 25px;
  text-align: right;
}

@media (max-width: 767px) {
  .pagination__wrapper {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .pagination__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

.pagination__wrapper small {
  margin-right: 15px;
  font-size: 12px !important;
}

.pagination__pager {
  display: inline-block;
}

@media (max-width: 1199px) {
  .pagination__pager {
    order: 1;
    margin: 0 auto;
  }
}

.pagination__pager small {
  margin-left: 30px;
}

@media (max-width: 1199px) {
  .pagination__pager small {
    position: absolute;
    bottom: 13px;
    left: 15px;
    width: 180px;
    text-align: left;
    margin-left: 0;
  }
}

@media (max-width: 320px) {
  .pagination__pager small {
    bottom: 0;
    width: 100px;
  }
}

.pagination__pager .pagination__left,
.pagination__pager .pagination__right {
  display: inline-block;
}

.pagination__pager .pagination__left button,
.pagination__pager .pagination__right button {
  width: 20px !important;
  height: 36px !important;
  vertical-align: bottom;
  padding: 12px 0 !important;
}

.pagination__pager .pagination__left button svg,
.pagination__pager .pagination__right button svg {
  margin-top: 3px;
}

.pagination__pager .pagination__left button:hover div,
.pagination__pager .pagination__right button:hover div {
  border-bottom: solid 1px #33383b;
}

.pagination__pager .pagination__right .pagination__three-dots {
  padding-right: 2px;
}

@media (min-width: 768px) {
  .pagination__pager .pagination__right .pagination__arrow-double-right {
    margin-left: 5px !important;
  }
}

.pagination__pager .pagination__left .pagination__three-dots {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .pagination__pager .pagination__left .pagination__arrow-double-left {
    margin-right: 5px !important;
  }
}

.pagination__pager > button {
  border: solid 1px #bfd0da !important;
  background-color: #fff !important;
  min-width: 36px !important;
  margin-right: 8px !important;
  line-height: 26px !important;
}

.pagination__pager > button.selected {
  background-color: #bfd0da !important;
}

.pagination__pager > button:hover {
  background-color: #bfd0da !important;
}

.pagination__pager > button.pagination__three-dots {
  padding-left: 1px;
  padding-right: 1px;
}

.pagination__pager > button span {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media (max-width: 320px) {
  .pagination__pager > button span {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.pagination__dropdown-actions {
  display: inline-block;
}

@media (max-width: 1199px) {
  .pagination__dropdown-actions {
    order: 2;
    width: 100% !important;
    margin-top: 20px;
    padding-right: 15px;
  }
}

.pagination__dropdown-actions small {
  font-weight: 600;
}

.pagination__dropdown-actions .Search-page__filters-pagination-wrapper {
  display: inline-block;
}

.pagination__dropdown-actions .Search-page__filters-pagination {
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  width: auto !important;
  height: 36px !important;
  background-color: #fff !important;
  vertical-align: middle;
}

.pagination__dropdown-actions .Search-page__filters-pagination > div > div > div {
  top: -10px !important;
  padding-left: 12px !important;
  padding-right: 38px !important;
  font-weight: 600;
}

.pagination__dropdown-actions .Search-page__filters-pagination button {
  top: -3px !important;
  padding: 12px 0 !important;
  width: 36px !important;
}

.pagination__dropdown-actions .Search-page__filters-pagination button svg {
  fill: #33383b !important;
  margin-top: -3px;
}

.col-xs-12.col-md-9 {
  position: relative;
}

.col-xs-12.col-md-9 .pagination__wrapper {
  position: absolute;
  width: 100%;
  left: 0;
}

/* Profile Page */

.profile-page-content-wrapper {
  position: relative;
}

.profile-page-charts-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .profile-page-charts-container {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .profile-page-charts-container {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .profile-page-charts-container {
    flex-direction: column;
  }
}

.profile-last-updated {
  color: #7c878e;
  font-size: 10px;
  display: inline-block;
  float: right;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .profile-last-updated {
    display: none;
  }
}

.profile-page-report-error-container {
  width: 100%;
  text-align: right;
}

.profile-page-report-error-content {
  min-width: 112px !important;
  background: #ffffff !important;
  border: #bfd0da 1px solid !important;
  height: 38px !important;
  line-height: 32px !important;
  text-align: center;
  cursor: pointer;
  float: right;
  color: #4c9eff;
  text-transform: uppercase;
  padding: 5px 10px 0px 10px;
  margin-bottom: 20px;
  margin-right: 55px;
}

.profile-page-report-error-content:hover {
  background-color: #bfd0da !important;
  color: #ffffff;
}

@media (max-width: 767px) {
  .profile-page-report-error-content {
    margin-bottom: 30px;
  }
}

.profile-tags-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 50px);
  position: relative;
}

.profile-tags-list > div {
  margin-bottom: 15px !important;
  margin-right: 10px !important;
  background-color: #d9e3e9 !important;
}

.profile-tags-list > div span {
  font-size: 12px !important;
  color: #33383b !important;
}

.profile-tags-list button:hover > div > div {
  width: 200px;
  height: 300px;
  white-space: normal;
  left: -200px;
}

.fr .profile-tags-list button:hover > div > div {
  width: 280px;
  left: -280px;
}

.profile-tags-list button span {
  white-space: normal !important;
}

.profile-tags-list button svg {
  color: #7c878e !important;
}

.profile-tags-list > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
}

.profile-special-notes,
.profile-grantmaking-notes {
  padding: 25px 35px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .profile-special-notes,
  .profile-grantmaking-notes {
    padding: 15px;
    margin-bottom: 10px;
  }
}

.profile-special-notes-note,
.profile-grantmaking-notes-note {
  font-size: 14px;
}

.profile-special-notes-note p,
.profile-grantmaking-notes-note p {
  font-size: 14px;
  font-weight: 300;
}

.profile-special-notes-note a,
.profile-grantmaking-notes-note a {
  font-weight: 600;
}

@media (max-width: 767px) {
  .profile-special-notes-note,
  .profile-grantmaking-notes-note {
    font-size: 12px;
  }
}

.profile-special-notes {
  background: #ffe9ac;
  border: solid 1px #ffd86c;
  color: #806416;
}

.profile-special-notes-note a {
  color: #806416;
}

.profile-grantmaking-notes {
  background: #ffe6ed;
  border: solid 1px #ffcddb;
  color: #840025;
}

.profile-grantmaking-notes-note a {
  color: #840025;
}

.react-tabs__tab-list {
  list-style: none;
  padding-left: 0;
  border-bottom: solid 1px #33383b;
  margin: 0 0 25px 0;
}

@media (max-width: 767px) {
  .react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
    margin: 0 -8px 15px;
    border-width: 2px;
  }
}

.react-tabs__tab-list .react-tabs__tab {
  display: inline-block;
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .react-tabs__tab-list .react-tabs__tab {
    padding: 18px 15px;
  }
}

.react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: solid 2px #33383b;
  font-weight: 600;
}

/*Vertical Tabs*/

.vertical-tabs ul[role='tablist'] {
  padding: 0;
  margin: 0;
}

.vertical-tabs ul[role='tablist'] li {
  list-style: none;
  padding: 15px;
  background-color: transparent;
  margin-right: 15px;
}

.vertical-tabs ul[role='tablist'] li.react-tabs__tab--selected {
  background-color: #bfd0da;
  font-weight: 600;
}

.vertical-tabs ul[role='tablist'] li:hover {
  cursor: pointer;
}

.vertical-tabs .react-tabs__tab-panel--selected {
  background: white;
  padding: 15px;
}

.vertical-tabs .extra_contact {
  display: flex;
  flex-wrap: wrap;
}

.vertical-tabs .extra_contact h4 {
  margin-bottom: 0;
}

.vertical-tabs .extra_contact > * {
  width: calc(50% - 15px);
  margin-right: 15px;
}

.vertical-tabs .extra_contact > *:nth-child(even) {
  width: 50%;
  margin-right: 0;
}

.Profile-gift-analysis {
  background: #fff;
  padding: 20px 20px 30px;
  overflow: auto;
  margin-bottom: 16px;
}

.Profile-gift-analysis h4 {
  margin: 0;
  padding-top: 0;
  font-size: 14px;
  font-weight: 600 !important;
  width: 150px;
}

.Profile-gift-analysis p {
  color: #7c878e;
  margin: 30px 0 40px;
}

@media (max-width: 767px) {
  .Profile-gift-analysis p {
    margin: 40px 0 40px;
  }
}

.Profile-gift-analysis .gift-analysis-filters {
  text-align: right;
  margin-bottom: 20px;
}

.Profile-gift-analysis-source {
  display: inline-block;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .Profile-gift-analysis-source {
    display: none;
  }
}

@media (min-width: 1200px) {
  .Profile-gift-analysis .pagination__wrapper {
    margin-top: 10px;
    display: inline-block;
    width: auto;
    float: right;
  }
}

.Profile-gift-analysis .Search-page__filters-year-wrapper,
.Profile-gift-analysis .Search-page__filters-cause-wrapper {
  display: inline-block;
  margin: 0 20px 0 0;
  padding-left: 50px;
  padding-bottom: 10px;
  vertical-align: top;
}

.fr .Profile-gift-analysis .Search-page__filters-year-wrapper,
.fr .Profile-gift-analysis .Search-page__filters-cause-wrapper {
  padding-left: 80px;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Search-page__filters-year-wrapper,
  .Profile-gift-analysis .Search-page__filters-cause-wrapper {
    float: left;
    display: none;
  }
}

.Profile-gift-analysis .Search-page__filters-year label {
  left: -40px;
}

.Profile-gift-analysis .Search-page__filters-cause label {
  left: -50px;
}

.fr .Profile-gift-analysis .Search-page__filters-cause label {
  left: -80px;
  width: 80px;
  top: 36px !important;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Search-page__filters-year,
  .Profile-gift-analysis .Search-page__filters-cause {
    width: 100% !important;
    height: 50px !important;
  }
}

@media (min-width: 768px) {
  .Profile-gift-analysis .Search-page__filters-year,
  .Profile-gift-analysis .Search-page__filters-cause {
    width: auto !important;
    min-width: 90px;
    margin-top: -14px !important;
    height: auto !important;
  }
}

.Profile-gift-analysis .Search-page__filters-year label,
.Profile-gift-analysis .Search-page__filters-cause label {
  color: #33383b !important;
  font-weight: 600;
  top: 46px !important;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Search-page__filters-year > div,
  .Profile-gift-analysis .Search-page__filters-cause > div {
    width: auto !important;
    max-width: 100px;
    float: right;
  }
}

.Profile-gift-analysis .Search-page__filters-year > div > div,
.Profile-gift-analysis .Search-page__filters-cause > div > div {
  font-weight: 600;
  width: auto !important;
  height: 36px !important;
  background: #fff !important;
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  font-size: 12px !important;
  padding-left: 10px;
}

.Profile-gift-analysis .Search-page__filters-year > div > div div,
.Profile-gift-analysis .Search-page__filters-cause > div > div div {
  line-height: 26px !important;
  height: auto !important;
}

.Profile-gift-analysis .Search-page__filters-year > div > div button,
.Profile-gift-analysis .Search-page__filters-cause > div > div button {
  top: -5px !important;
}

.Profile-gift-analysis .Search-page__filters-year > div > div button svg,
.Profile-gift-analysis .Search-page__filters-cause > div > div button svg {
  fill: #33383b !important;
}

.Profile-gift-analysis .gift_commentary {
  text-align: left;
}

.Profile-gift-analysis .Profile-page__filters-actions {
  display: inline-block;
}

.Profile-gift-analysis .Profile-page__filters-actions button {
  background-color: #bfd0da !important;
  border-radius: 3px !important;
  height: 38px !important;
  margin-left: 10px !important;
  top: -1px !important;
  min-width: 66px !important;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Profile-page__filters-actions button {
    margin: 15px 0 0 0 !important;
  }
}

.Profile-gift-analysis .Profile-page__filters-actions button:hover {
  background-color: #9badb7 !important;
}

.Profile-gift-analysis .Profile-page__filters-actions .Profile-page__filters-button-clear {
  background: none !important;
}

.Profile-gift-analysis .Profile-page__filters-actions .Profile-page__filters-button-clear:hover,
.Profile-gift-analysis .Profile-page__filters-actions .Profile-page__filters-button-clear:focus {
  background: none !important;
}

.Profile-gift-analysis .Profile-page__filters-gift-size-wrapper {
  display: inline-block;
  margin: 0 20px 0 0;
}

.Profile-gift-analysis .Profile-page__filters-gift-size-wrapper label {
  position: relative !important;
  top: 0 !important;
  color: #33383b !important;
  font-size: 12px !important;
  font-weight: 600;
  transform: none !important;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-wrapper {
    margin: 15px 0 0;
    display: none;
  }
}

@media (min-width: 1200px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-wrapper {
    display: inline-block;
  }
}

.Profile-gift-analysis .Profile-page__filters-gift-size-wrapper small {
  font-weight: 600;
  font-size: 12px !important;
  margin: 0 10px;
}

.Profile-gift-analysis .Profile-page__filters-gift-size-from input {
  margin-left: 15px;
}

.Profile-gift-analysis .Profile-page__filters-gift-size-from,
.Profile-gift-analysis .Profile-page__filters-gift-size-to {
  min-width: 100px !important;
  width: auto !important;
  height: auto !important;
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-from,
  .Profile-gift-analysis .Profile-page__filters-gift-size-to {
    min-width: 90px !important;
  }
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-from,
  .Profile-gift-analysis .Profile-page__filters-gift-size-to {
    width: auto !important;
  }
}

@media (max-width: 767px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-from label,
  .Profile-gift-analysis .Profile-page__filters-gift-size-to label {
    top: 5px !important;
  }
}

.Profile-gift-analysis .Profile-page__filters-gift-size-from input,
.Profile-gift-analysis .Profile-page__filters-gift-size-to input {
  height: 38px !important;
  background: #fff !important;
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  font-size: 12px !important;
  padding: 0 10px !important;
  margin-top: 0 !important;
  width: 100px !important;
}

@media (max-width: 320px) {
  .Profile-gift-analysis .Profile-page__filters-gift-size-from input,
  .Profile-gift-analysis .Profile-page__filters-gift-size-to input {
    width: 80px !important;
  }
}

.Profile-gift-analysis .Profile-page__filters-gift-size-from input::placeholder,
.Profile-gift-analysis .Profile-page__filters-gift-size-to input::placeholder {
  color: #33383b !important;
  font-weight: 600;
}

.GiftAnalysisTable-RecepientName {
  font-weight: 600;
}

@media (min-width: 768px) {
  .GiftAnalysisTable-RecepientName {
    width: 200px !important;
  }
}

@media (min-width: 992px) {
  .GiftAnalysisTable-RecepientName {
    width: 200px !important;
  }
}

@media (min-width: 1200px) {
  .GiftAnalysisTable-RecepientName {
    width: 200px !important;
  }
}

@media (min-width: 768px) {
  .GiftAnalysisTable-Amount {
    width: 65px !important;
  }
}

@media (min-width: 992px) {
  .GiftAnalysisTable-Amount {
    width: 65px !important;
  }
}

@media (min-width: 1200px) {
  .GiftAnalysisTable-Amount {
    width: 65px !important;
  }
}

@media (min-width: 768px) {
  .GiftAnalysisTable-Year {
    width: 50px !important;
  }
}

@media (min-width: 992px) {
  .GiftAnalysisTable-Year {
    width: 50px !important;
  }
}

@media (min-width: 1200px) {
  .GiftAnalysisTable-Year {
    width: 50px !important;
  }
}

.GiftAnalysisTable-CharitySize {
  text-align: right !important;
  white-space: normal !important;
}

@media (min-width: 768px) {
  .GiftAnalysisTable-CharitySize {
    width: 100px !important;
  }
}

@media (min-width: 992px) {
  .GiftAnalysisTable-CharitySize {
    width: 100px !important;
  }
}

@media (min-width: 1200px) {
  .GiftAnalysisTable-CharitySize {
    width: 100px !important;
  }
}

.GiftAnalysisTable-CharitySize .GiftAnalysisTable-SortLabel {
  width: calc(100% - 12px);
  float: left;
}

.GiftAnalysisTable-CharitySize .GiftAnalysisTable-SortArrow {
  width: 10px;
  float: left;
}

.GiftAnalysisTable-DetailButton {
  text-align: right !important;
}

@media (min-width: 768px) {
  .GiftAnalysisTable-DetailButton {
    width: 45px !important;
  }
}

@media (min-width: 992px) {
  .GiftAnalysisTable-DetailButton {
    width: 45px !important;
  }
}

@media (min-width: 1200px) {
  .GiftAnalysisTable-DetailButton {
    width: 45px !important;
  }
}

.GiftAnalysisTable-SortLabel {
  float: left;
}

.GiftAnalysisTable-SortArrow {
  width: 10px;
  float: left;
}

.GiftAnalysisTable-SortArrow::after {
  content: '';
  clear: both;
  display: table;
}

.Profile-page-gift-data thead tr {
  border-bottom: 1px solid transparent !important;
}

.Profile-page-gift-data tr th {
  padding-left: 0 !important;
  vertical-align: top;
}

.Profile-page-gift-data tr th[data-selected='true'] {
  text-decoration: underline;
  cursor: pointer;
  color: #000000 !important;
}

.Profile-page-gift-data tr th[data-selected='false'] {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(158, 158, 158);
}

.Profile-page-gift-data tr th img {
  width: 10px;
  height: auto;
  margin-left: 5px;
}

.Profile-page-gift-data td {
  padding: 5px 20px 5px 0 !important;
  height: 50px !important;
  white-space: initial !important;
}

.Profile-page-gift-data td:last-child button {
  text-align: right !important;
  padding-right: 0 !important;
}

.Profile-page-gift-data td:last-child button svg {
  color: #7c878e !important;
}

.Profile-gift-analysis-dialog h3 {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
}

.Profile-gift-analysis-dialog-modal-name {
  font-weight: 600;
  display: block;
  line-height: 1.4;
  margin-bottom: 10px;
}

.Profile-gift-analysis-dialog > div > div {
  max-width: 635px !important;
}

.Profile-gift-analysis-dialog div > div > div > div {
  font-size: 12px !important;
  line-height: 2;
  margin-right: 10px !important;
}

.Profile-gift-analysis-dialog div > div > div > div svg {
  fill: #7c878e !important;
}

.Profile-gift-analysis-dialog button {
  background-color: transparent !important;
  color: #4c9eff !important;
  margin-right: -10px !important;
}

.Profile-gift-analysis-chart {
  background: #fff;
  padding: 20px 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .Profile-gift-analysis-chart {
    width: auto;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .Profile-gift-analysis-chart {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .Profile-gift-analysis-chart {
    width: auto;
  }
}

@media (max-width: 767px) {
  .Profile-gift-analysis-chart:first-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .Profile-gift-analysis-chart:first-child {
    margin-right: 8px;
  }
}

@media (min-width: 992px) {
  .Profile-gift-analysis-chart:first-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .Profile-gift-analysis-chart:nth-child(2) {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .Profile-gift-analysis-chart:nth-child(2) {
    margin-left: 8px;
  }
}

@media (min-width: 992px) {
  .Profile-gift-analysis-chart:nth-child(2) {
    margin-left: 0;
  }
}

.Profile-gift-analysis-chart h4 {
  margin: 0;
  padding-top: 0;
  font-size: 14px;
  font-weight: 600 !important;
}

.Profile-gift-analysis-chart p {
  color: #7c878e;
  margin: 30px 0 40px;
}

@media (max-width: 767px) {
  .Profile-gift-analysis-chart p {
    margin: 40px 0 40px;
  }
}

.Profile-gift-analysis-loading {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.Profile-gift-analysis-subtitle {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10px;
  font-style: oblique;
  color: #666666;
}

.Profile-gift-analysis-chart-container-cause {
  margin: 20px auto;
}

.Profile-gift-analysis-chart-container-region {
  margin: 0 auto;
}

.Profile-gift-analysis-mobile-desc {
  display: none;
}

@media (max-width: 767px) {
  .Profile-gift-analysis-mobile-desc {
    margin: 20px 0;
    display: block;
  }

  .Profile-gift-analysis-mobile-desc div {
    border-bottom: 1px solid #d9e3e9;
    padding: 12px 0;
  }
}

.Profile-gift-analysis-tooltip {
  position: absolute;
  text-align: left;
  width: 220px;
  min-height: 20px;
  padding: 10px;
  font: 12px sans-serif;
  background: #ffffff;
  border: 2px solid #d9e3e9;
  border-radius: 8px;
  pointer-events: none;
  display: none;
  z-index: 2000;
}

.profile-programs .profile-programs-regions p {
  font-weight: 300;
  margin: 0;
}

.profile-programs .profile-programs-regions .profile-info-wrapper {
  margin-bottom: 20px;
}

.profile-programs .profile-programs-regions-item {
  list-style-position: inside;
}

.profile-programs .profile-programs-regions.main {
  display: flex;
  justify-content: space-between;
}

.profile-programs .profile-programs-regions.main > div {
  width: 48%;
}

.profile-programs .profile-programs-regions h5 {
  font-size: 12px;
  font-weight: 600;
}

.profile-programs .profile-programs-regions ul {
  padding: 0;
}

.profile-programs .profile-programs-regions ul li {
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  margin-left: 10px;
}

.profile-programs .profile-programs-regions ul li::before {
  content: '';
  min-width: 3px;
  min-height: 3px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #404040;
  display: block;
  margin-right: 20px;
  margin-top: 8px;
}

.profile-programs .profile-programs-regions ul li strong,
.profile-programs .profile-programs-regions ul li b,
.profile-programs .profile-programs-regions ul li i {
  margin-right: 5px;
}

.profile-programs .profile-programs-regions.profile-programs-funding-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile-programs .profile-programs-regions.profile-programs-funding-info > div {
  width: 100%;
}

.profile-programs
  .profile-programs-regions.profile-programs-funding-info
  > div.profile-info-wrapper {
  width: 45%;
}

.profile-programs
  .profile-programs-regions.profile-programs-funding-info
  > div.profile-info-wrapper
  div {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: 300;
  margin-left: 30px;
}

.profile-programs
  .profile-programs-regions.profile-programs-funding-info
  > div.profile-info-wrapper
  div::before {
  content: '';
  min-width: 3px;
  min-height: 3px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #404040;
  display: block;
  margin-right: 20px;
}

.profile-programs div [style='overflow: hidden;'] .profile-about-program-expand {
  color: #4c9eff;
}

.profile-programs .profile-about-program-expand {
  padding-top: 15px;
  display: inline-block;
  color: #7c878e;
}

.profile-about__general-info > h4 {
  margin: 0 0 25px 0 !important;
}

.profile-programs-regions,
.profile-deadlines-wrapper-item,
.profile-about__general-info,
.profile-about-linkedin-widget,
.profile-financial-data-wraper,
.profile-positions-wrapper {
  border-radius: 2px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 12px;
}

.profile-programs-regions h4,
.profile-deadlines-wrapper-item h4,
.profile-about__general-info h4,
.profile-about-linkedin-widget h4,
.profile-financial-data-wraper h4,
.profile-positions-wrapper h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.profile-programs-regions h3,
.profile-deadlines-wrapper-item h3,
.profile-about__general-info h3,
.profile-about-linkedin-widget h3,
.profile-financial-data-wraper h3,
.profile-positions-wrapper h3 {
  font-weight: normal;
  margin: 0 0 10px 0;
}

.profile-programs-regions big,
.profile-deadlines-wrapper-item big,
.profile-about__general-info big,
.profile-about-linkedin-widget big,
.profile-financial-data-wraper big,
.profile-positions-wrapper big {
  font-size: 18px;
}

.Collapsible {
  border-radius: 2px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 15px;
}

.Collapsible__trigger {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  display: block;
  cursor: pointer;
}

.Collapsible__trigger.is-closed::before,
.Collapsible__trigger.is-closed::after {
  content: '';
  position: absolute;
  top: 10px;
  width: 8px;
  border: 1px solid #000;
}

.Collapsible__trigger.is-closed::before {
  right: 8px;
  transform: rotateZ(45deg);
}

.Collapsible__trigger.is-closed::after {
  right: 2px;
  transform: rotateZ(-45deg);
}

.Collapsible__trigger.is-open::before,
.Collapsible__trigger.is-open::after {
  content: '';
  position: absolute;
  top: 10px;
  width: 8px;
  border: 1px solid #000;
}

.Collapsible__trigger.is-open::before {
  right: 8px;
  transform: rotateZ(135deg);
}

.Collapsible__trigger.is-open::after {
  right: 2px;
  transform: rotateZ(-135deg);
}

.Collapsible a {
  color: #4c9eff;
}

.Collapsible h4 {
  font-weight: 600;
}

.Collapsible li {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 1.5em;
}

.Collapsible .profile-info-wrapper {
  width: 45% !important;
  display: inline-block;
  vertical-align: top;
  padding-right: 15px;
}

.Collapsible .profile-info-wrapper ul {
  margin-left: -40px;
}

.Collapsible .profile-info-wrapper li,
.Collapsible .profile-info-wrapper p {
  padding-right: 20px;
}

.Collapsible .profile-info-wrapper:nth-child(3) {
  width: 100% !important;
}

.Collapsible .profile-info-procedure {
  width: 100% !important;
}

.profile-programs-dealines-wrapper {
  margin-top: 1.33em;
}

.profile-programs-dealines-wrapper .row {
  padding-bottom: 10px;
}

.profile-about__wrapper {
  position: relative;
}

.profile-about__wrapper strong {
  font-weight: 600;
}

.profile-about__wrapper h3 {
  width: 70%;
}

@media (max-width: 767px) {
  .profile-about__wrapper h3 {
    font-size: 18px;
    width: 100%;
  }
}

.profile-about-mission {
  padding: 10px 0 30px 0;
}

.profile-about-mission a {
  padding-top: 10px;
  display: inline-block;
}

.profile-about-mission-collapse {
  color: #7c878e;
}

@media (max-width: 767px) {
  .profile-about-year {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .profile-about-year {
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 20px;
  }
}

.profile-about-info {
  margin-bottom: 30px;
}

.profile-about-info .profile-info-wrapper {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .profile-about-info .col-md-4:last-child h4 {
    margin-top: 30px;
  }
}

.profile-about-info li,
.profile-about-contact-info li {
  display: block;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .profile-about-info,
  .profile-about-contact-info {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .profile-about-info .col-xs-12,
  .profile-about-contact-info .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.profile-about-contact-info svg {
  float: left;
  margin-right: 3px;
}

@media (max-width: 767px) {
  .profile-about-contact-info svg {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .profile-about-contact-info svg {
    margin-bottom: 45px;
  }
}

.profile-about-contact-info .col-md-4 span {
  display: block;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.profile-about-contact-info .col-md-4:nth-of-type(3) strong {
  display: inline-block;
  width: 60px;
}

@media (max-width: 767px) {
  .profile-about-contact-info .col-md-4:nth-of-type(3) strong {
    width: 80px;
  }
}

@media (min-width: 768px) {
  .profile-about-contact-info .col-md-4:last-child strong {
    display: block;
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .profile-about-contact-info .col-md-4:last-child strong {
    width: 80px;
    display: inline-block;
  }
}

.profile-about-linkedin-widget {
  position: relative;
  padding-bottom: 306px;
  padding-top: 20px;
  height: 0;
  overflow: scroll;
  overflow-y: hidden;
}

@media (max-width: 767px) {
  .profile-about-linkedin-widget {
    overflow: scroll;
  }
}

.profile-about-linkedin-widget iframe {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 100% !important;
}

.profile-financial-data-wraper small {
  color: #7c878e;
  display: block;
  padding: 6px 0;
  font-size: 12px !important;
  margin-bottom: 10px;
}

.profile-financial-data-wraper span {
  margin-top: 25px;
  display: block;
}

.profile-financial-data-wraper strong {
  font-weight: 600;
}

.profile-financial-data-wraper table {
  color: #33383b;
  position: relative;
  table-layout: auto !important;
  border-collapse: unset !important;
}

.profile-financial-data-wraper table thead th,
.profile-financial-data-wraper table tbody td {
  text-align: right !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.profile-financial-data-wraper table thead tr {
  border-color: transparent !important;
  height: 38px !important;
}

.profile-financial-data-wraper table thead tr th {
  height: 38px !important;
  color: #33383b !important;
  padding: 0 5px !important;
  text-align: center !important;
}

.profile-financial-data-wraper table tbody tr {
  border-bottom: 0 !important;
}

.profile-financial-data-wraper table tbody tr:first-child::before {
  border: none;
}

.profile-financial-data-wraper table tbody tr:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: solid 1px #bfd0da;
}

.profile-financial-data-wraper table tbody td {
  font-size: 12px !important;
  padding: 0 5px !important;
  text-align: center !important;
}

@media (max-width: 767px) {
  .profile-financial-data-wraper table tbody td {
    font-size: 10px !important;
  }
}

.profile-financial-data-wraper table tbody td:first-child {
  white-space: initial !important;
  text-align: left !important;
  width: auto;
}

.profile-financial-data-wraper .data-name {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f5f8f9 !important;
  border-right: solid 2px #bfd0da;
}

.profile-financial-data-wraper .odd-column {
  background-color: #f5f8f9 !important;
}

.profile-financial-data-wraper .data-name strong {
  white-space: nowrap;
}

.profile-positions-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.profile-positions-wrapper h4 {
  margin-bottom: 15px;
}

.profile-positions-wrapper li {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 2.5em;
  line-height: 1.6;
}

.profile-positions-wrapper li:last-child {
  margin-bottom: 20px;
}

.profile-positions-wrapper .col-xs-6 {
  padding-right: 0;
  padding-left: 0;
}

.fr .profile-about-contact-info .col-md-4 span strong {
  display: inline-block;
  width: 75px;
}

@media (max-width: 767px) {
  .fr .profile-about-contact-info .col-md-4 span strong {
    width: 80px;
  }
}

.Full-card__notes {
  background-color: #fff8e6;
  padding: 15px;
  position: relative;
}

@media (max-width: 767px) {
  .Full-card__notes {
    padding: 30px 15px 15px;
  }
}

@media (min-width: 992px) {
  .Full-card__notes {
    max-width: 310px;
    min-width: 310px;
    float: right;
    margin-top: 55px;
    text-align: left;
  }
}

.Full-card__notes svg {
  width: 18px !important;
  height: 18px !important;
  color: #7c878e !important;
  padding: 0 !important;
}

.Full-card__notes svg:hover {
  color: #33383b !important;
}

.Full-card__notes:before {
  content: '';
  position: absolute;
  top: 0%;
  right: 0%;
  width: 0px;
  height: 0px;
  border-bottom: 25px solid #ffe9ac;
  border-right: 25px solid white;
}

.Full-card__notes small {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

@media (max-width: 767px) {
  .Full-card__notes small {
    font-size: 12px;
  }
}

.Full-card__notes > div {
  margin-bottom: 15px;
}

.Full-card__notes > div > div > div {
  vertical-align: middle;
  display: block;
  text-align: right;
  margin-top: 10px;
}

.Full-card__notes-date {
  color: #7c878e;
  margin: 5px 0;
  font-weight: 300;
}

.Full-card__notes-edit {
  margin-right: 5px !important;
}

.Full-card__notes-edit,
.Full-card__notes-delete,
.Full-card__notes-cancel {
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.Full-card__notes-note {
  word-wrap: break-word;
}

.Full-card__notes-note span {
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  float: right;
}

.Full-card__notes-new-actions {
  margin-top: 5px;
  width: 100%;
}

.Full-card__notes-new-actions::after {
  content: '';
  clear: both;
  display: table;
}

.Full-card__notes-see-all {
  margin-top: -10px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.Full-card__notes-see-all span {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Full-card__notes input {
  width: 100%;
  height: 34px;
  border: none;
  text-indent: 10px;
  outline: none;
}

.Full-card__notes-cancel {
  margin-left: 5px !important;
  float: left;
}

.Full-card__notes-cancel svg {
  vertical-align: middle;
}

.Full-card__notes-close {
  color: #7c878e !important;
}

.Full-card__notes-done,
.Full-card__notes-add {
  float: right;
}

.Full-card__notes-done::after,
.Full-card__notes-add::after {
  content: '';
  clear: both;
  display: table;
}

.Full-card__notes-done,
.Full-card__notes-add,
.Full-card__notes-close {
  height: 18px !important;
  line-height: 14px !important;
  min-width: 35px !important;
  margin-right: 5px !important;
}

.Full-card__notes-done span,
.Full-card__notes-add span,
.Full-card__notes-close span {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.user-edit form {
  margin-bottom: 30px !important;
}

.user-edit form > div {
  display: block !important;
  width: 50% !important;
  height: 58px !important;
}

.user-edit form label {
  font-size: 12px !important;
  color: #7c878e !important;
  top: 15px !important;
}

.user-edit form input {
  border-radius: 3px;
  width: 100% !important;
  height: 47px !important;
  margin-top: 0 !important;
}

.user-edit form button {
  background-color: #ffc72c !important;
  display: block !important;
  height: 48px !important;
  margin-top: 15px !important;
  border: none !important;
}

.user-edit form button:hover,
.user-edit form button:focus {
  background-color: #ffd86c !important;
}

@media (max-width: 767px) {
  .user-edit form > div {
    width: 100% !important;
  }
}

/* Search Page */

.Search-page {
  padding-bottom: 20px;
  margin-top: -28px;
}

.Search-page__results {
  color: #7c878e;
  font-weight: 400;
  margin-bottom: 1.4rem;
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .Search-page__results {
    font-size: 12px !important;
    padding-left: 5px;
    min-height: 17px;
  }
}

.Search-page__view {
  text-align: right;
  margin-right: -11px !important;
  margin-top: -10px;
}

.Search-page__view-expanded.active svg,
.Search-page__view-collapsed.active svg {
  color: #7c878e !important;
}

.Search-page__view-expanded svg,
.Search-page__view-collapsed svg {
  color: #bfd0da !important;
}

.Search-page hr {
  width: 100%;
}

.Search-page__buttons-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  margin-right: -10px;
}

.Search-page__buttons-container > * {
  margin-left: 10px !important;
}

.Search-page h3 {
  font-weight: 300;
}

.Search-page .profile-tags-list {
  padding-top: 20px;
  width: 100%;
  margin-left: 0 !important;
}

.Search-page .pill-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Search-page .rounded-icon {
  display: flex;
  height: 23px;
  width: 23px;
  background: #ffffff;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: -8px;
}

.Search-page .rounded-icon svg path {
  fill: #979797;
}

.Search_tiles {
  width: 100%;
}

.Search_tiles--header {
  border-radius: 1px;
  background-color: #fff2cd;
  margin-bottom: 30px;
  color: #806416;
  height: 43px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 30px;
}

.featured .Search_tiles--header {
  margin-top: 0;
}

[data-featured='NaN'] .Search_tiles--header,
[data-featured='false'] .Search_tiles--header {
  margin-top: 0;
}

.Search_tiles--header.more {
  margin-top: 0;
}

.Search_tiles--header.more[data-hidden='true'] {
  margin-bottom: 0;
}

.Search_tiles--content {
  padding-bottom: 30px;
}

.refresh-container {
  width: 100%;
  text-align: center;
}

.Search-page__results > span {
  font-size: inherit !important;
}

.search-title {
  font-weight: 400;
  display: inline-block;
  font-size: 26px;
  line-height: 50px;
}

@media (min-width: 768px) {
  .search-title {
    margin-bottom: 30px;
    line-height: 45px;
  }
}

.Row-search-header {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.Row-search-header .Row-search-header--title {
  margin-right: 20px;
}

.Row-search-header .Row-search-header--title h3 {
  white-space: nowrap;
  font-weight: 600;
}

.Row-search-header .Row-search-header--title h3 i {
  font-weight: 300;
}

@media (max-width: 991px) {
  .Row-search-header .Row-search-header--title h3 {
    padding-left: 10px;
  }
}

.Row-search-header .Material-cards__expanded-state.add {
  display: block !important;
}

@media (max-width: 991px) {
  .Row-search-header {
    flex-wrap: wrap;
  }

  .Row-search-header .Row-search-header--title {
    width: 100%;
  }

  .Row-search-header .Search-page__buttons-container {
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
  }

  .Row-search-header .Search-page__buttons-container button {
    margin-bottom: 20px !important;
  }
}

.Saved-filter {
  margin-top: 10px;
}

.Saved-filter--list {
  display: flex;
  flex-direction: column;
}

.Saved-filter--list .Saved-filter--list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #bfd0da;
}

.Saved-filter--list .Saved-filter--list-item[data-selected='true'] {
  background-color: #f5f8f9;
}

.Saved-filter--list .Saved-filter--list-item > div {
  width: 50%;
  font-weight: 600;
}

.Saved-filter--list .Saved-filter--list-item .Saved-filter--list-item--selected {
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  color: #4c9eff;
}

.Saved-filter--list .Saved-filter--list-item .Saved-filter--list-item--delete {
  margin-right: 5px;
  cursor: pointer;
}

.Dialog-buttons--wrapper {
  height: 96px;
  background-color: #f5f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dialog-buttons--wrapper button:last-child {
  margin-left: 30px !important;
  display: block !important;
}

@media (min-width: 768px) {
  .Dialog-buttons--wrapper button:last-child {
    display: block !important;
  }
}

.Save-search {
  font-weight: 600;
  margin-top: 10px;
}

.Save-search .Save-search--input {
  width: calc(100% - 10px);
  height: 37px;
  border: 1px solid #bfd0da;
  margin-top: 15px;
  padding: 5px;
  box-sizing: content-box;
}

.Search-page__filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.Search-page__filters small {
  font-weight: 600;
  font-size: 12px !important;
  margin: 0 10px;
}

@media (max-width: 320px) {
  .Search-page__filters small {
    margin: 0 5px 0 -3px;
  }
}

.Search-page__filters small span {
  font-weight: normal !important;
}

@media (max-width: 767px) {
  .Search-page__filters-language,
  .Search-page__filters-requests,
  .Search-page__filters-typical-from,
  .Search-page__filters-typical-to,
  .Search-page__filters-sorting {
    width: 100% !important;
    height: 50px !important;
  }
}

@media (min-width: 768px) {
  .Search-page__filters-language,
  .Search-page__filters-requests,
  .Search-page__filters-typical-from,
  .Search-page__filters-typical-to,
  .Search-page__filters-sorting {
    width: auto !important;
    min-width: 138px;
  }
}

.Search-page__filters-language label,
.Search-page__filters-requests label,
.Search-page__filters-typical-from label,
.Search-page__filters-typical-to label,
.Search-page__filters-sorting label {
  color: #33383b !important;
  font-weight: 600;
}

@media (max-width: 767px) {
  .Search-page__filters-language label,
  .Search-page__filters-requests label,
  .Search-page__filters-typical-from label,
  .Search-page__filters-typical-to label,
  .Search-page__filters-sorting label {
    top: 46px !important;
  }
}

@media (min-width: 768px) {
  .Search-page__filters-language label,
  .Search-page__filters-requests label,
  .Search-page__filters-typical-from label,
  .Search-page__filters-typical-to label,
  .Search-page__filters-sorting label {
    top: 0px !important;
  }
}

@media (max-width: 767px) {
  .Search-page__filters-language > div,
  .Search-page__filters-requests > div,
  .Search-page__filters-typical-from > div,
  .Search-page__filters-typical-to > div,
  .Search-page__filters-sorting > div {
    width: auto !important;
    max-width: 100px;
    float: right;
  }
}

.Search-page__filters-language > div > div,
.Search-page__filters-requests > div > div,
.Search-page__filters-typical-from > div > div,
.Search-page__filters-typical-to > div > div,
.Search-page__filters-sorting > div > div {
  font-weight: 600;
  width: auto !important;
  height: 36px !important;
  background: #fff !important;
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  font-size: 12px !important;
  padding-left: 10px;
}

.Search-page__filters-language > div > div div,
.Search-page__filters-requests > div > div div,
.Search-page__filters-typical-from > div > div div,
.Search-page__filters-typical-to > div > div div,
.Search-page__filters-sorting > div > div div {
  line-height: 26px !important;
}

.Search-page__filters-language > div > div button,
.Search-page__filters-requests > div > div button,
.Search-page__filters-typical-from > div > div button,
.Search-page__filters-typical-to > div > div button,
.Search-page__filters-sorting > div > div button {
  top: -5px !important;
}

.Search-page__filters-language > div > div button svg,
.Search-page__filters-requests > div > div button svg,
.Search-page__filters-typical-from > div > div button svg,
.Search-page__filters-typical-to > div > div button svg,
.Search-page__filters-sorting > div > div button svg {
  fill: #33383b !important;
}

@media (min-width: 768px) {
  .Search-page__filters-requests-wrapper {
    margin-left: 20px;
  }
}

@media (max-width: 1199px) {
  .Search-page__filters-requests-wrapper:after {
    content: '\a';
    white-space: pre;
    width: 220px;
    display: inline-block;
  }
}

.Search-page__filters-typical-wrapper {
  margin: 10px 0 0;
}

@media (max-width: 767px) {
  .Search-page__filters-typical-wrapper {
    margin: 15px 0 0;
  }
}

@media (min-width: 1200px) {
  .Search-page__filters-typical-wrapper {
    margin: 0 0 0 20px;
  }
}

@media (max-width: 767px) {
  .Search-page__filters-sorting-wrapper label {
    display: none;
  }

  .Search-page__filters-sorting-wrapper div {
    margin-top: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 768px) {
  .Search-page__filters-sorting-wrapper {
    margin: 10px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .Search-page__filters-sorting-wrapper {
    position: absolute;
    right: 0;
    margin: 0;
  }
}

.Search-page__filters-sorting-wrapper .Search-page__filters-sorting button svg {
  fill: #33383b !important;
}

.Search-page__filters-typical-from,
.Search-page__filters-typical-to {
  min-width: 100px !important;
}

@media (max-width: 767px) {
  .Search-page__filters-typical-from,
  .Search-page__filters-typical-to {
    min-width: 90px !important;
  }
}

@media (max-width: 767px) {
  .Search-page__filters-typical-from,
  .Search-page__filters-typical-to {
    width: auto !important;
  }
}

@media (max-width: 767px) {
  .Search-page__filters-typical-from label,
  .Search-page__filters-typical-to label {
    top: 5px !important;
  }
}

.Search-page__filters-typical-from input,
.Search-page__filters-typical-to input {
  width: auto !important;
  height: 38px !important;
  background: #fff !important;
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  font-size: 12px !important;
  padding: 0 10px !important;
  width: 100px !important;
}

@media (max-width: 320px) {
  .Search-page__filters-typical-from input,
  .Search-page__filters-typical-to input {
    width: 80px !important;
  }
}

.Search-page__filters-typical-from input::placeholder,
.Search-page__filters-typical-to input::placeholder {
  color: #33383b !important;
  font-weight: 600;
}

.Search-page__filters-menu-item {
  color: #33383b !important;
  border-bottom: 1px solid #bfd0da !important;
}

.Search-page__filters-menu-item.last {
  border: 0 none !important;
}

.Search-page__filters-menu-item:hover {
  background-color: #d9e3e9 !important;
}

.Search-page__filters-actions {
  display: inline-block;
}

.Search-page__filters-actions button {
  background-color: #bfd0da !important;
  border-radius: 3px !important;
  height: 38px !important;
  margin-left: 10px !important;
  top: -1px !important;
  min-width: 66px !important;
}

@media (max-width: 767px) {
  .Search-page__filters-actions button {
    margin: 15px 0 0 0 !important;
  }
}

.Search-page__filters-actions button:hover {
  background-color: #9badb7 !important;
}

.Search-page__filters-actions .Search-page__filters-button-clear {
  background: none !important;
}

.Search-page__filters-actions .Search-page__filters-button-clear:hover,
.Search-page__filters-actions .Search-page__filters-button-clear:focus {
  background: none !important;
}

.Search-page__filters-mobile {
  background-color: #f5f8f9 !important;
  padding: 20px 20px 20px 30px;
}

@media (max-width: 320px) {
  .Search-page__filters-mobile {
    width: 260px !important;
  }
}

@media (max-width: 767px) {
  .Search-page__filters-mobile {
    z-index: 25000 !important;
  }
}

.Search-page__filters-mobile .Search-page__filters-language-wrapper,
.Search-page__filters-mobile .Search-page__filters-requests-wrapper {
  width: 100% !important;
}

.Search-page__filters-mobile .Search-page__facets {
  margin-top: 0;
}

.Search-page__filters-mobile .Search-page__facets > div hr {
  display: none;
}

.Search-page__mobile-filters-button {
  background-color: #fff !important;
  border: solid 1px #bfd0da !important;
  min-width: 68px !important;
  height: 38px !important;
}

.Search-page__mobile-filters-button span {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.Search-page__mobile-filters-button:hover {
  background-color: #bfd0da !important;
}

@media (min-width: 768px) {
  .fr .Search-page__filters-language {
    min-width: 210px;
  }
}

@media (min-width: 768px) {
  .fr .Search-page__filters-requests {
    min-width: 180px;
  }
}

.Search-page__facets {
  position: relative;
  margin-top: -17px;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-flow: column;
}

@media screen and (min-width: 1024px) {
  .Search-page__facets {
    padding-right: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Search-page__facets {
    padding-right: 20px;
  }
}

.Search-page__facets hr {
  width: 100%;
}

.Search-page__facets small {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  display: inline-block;
}

.Search-page__facets p {
  position: relative;
}

.Search-page__facets p button {
  min-width: 36px !important;
  position: absolute !important;
  right: 0 !important;
  top: -10px !important;
}

.Search-page__facets p button span {
  color: #7c878e;
  font-size: 12px !important;
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.Search-page__facets-facet:nth-of-type(1) {
  order: 5;
}

.Search-page__facets-facet:nth-of-type(2) {
  order: 1;
}

.Search-page__facets-facet:nth-of-type(3) {
  order: 6;
}

.Search-page__facets-facet:nth-of-type(4) {
  order: 2;
}

.Search-page__facets-facet:nth-of-type(5) {
  order: 3;
}

.Search-page__facets-facet:nth-of-type(6) {
  order: 4;
}

.Search-page__facets-item > div {
  margin-bottom: 10px;
}

.Search-page__facets-item > div > div {
  font-weight: 600;
  margin: -10px -6px -12px -16px !important;
}

@media (min-width: 768px) {
  .Search-page__facets-item > div > div {
    margin: -10px -6px -12px -6px !important;
  }
}

.Search-page__facets-item-grandchild button {
  padding: 0 !important;
  width: 38px !important;
  height: 38px !important;
}

.Search-page__facets-item-grandchild > div > div {
  padding: 8px 35px 8px 0px !important;
}

.Search-page__facets-item-grandchild.selected > div > div > div {
  font-weight: 600;
}

.Search-page__facets-item-grandchild.selected > div > div > button:first-of-type {
  margin: 0 !important;
  padding: 0 !important;
  z-index: 999;
  left: initial !important;
  background: none !important;
  right: 35px;
}

.Search-page__facets-item-grandchild svg {
  width: 18px !important;
  height: 18px !important;
  margin: 0 9px !important;
  fill: #404040 !important;
  stroke: #404040;
  stroke-width: 1px;
  -webkit-font-smoothing: antialiased;
}

.Search-page__facets-item-child {
  line-height: 20px !important;
  padding-left: 10px !important;
}

.Search-page__facets-item-child.selected > div > div > button:first-of-type {
  margin: 0 !important;
  padding: 0 !important;
  top: 10px !important;
  left: initial !important;
  background: none !important;
  right: 35px;
}

.Search-page__facets-item-child.selected > div > div > button:first-of-type svg {
  fill: #404040 !important;
  width: 18px !important;
  height: 18px !important;
  stroke: #404040;
  stroke-width: 1px;
  -webkit-font-smoothing: antialiased;
}

.Search-page__facets-item-child.selected.checkbox > div > div > button:first-of-type {
  right: 10px;
}

.Search-page__facets-item-child > div > div button:last-of-type {
  margin: -5px -6px 0 0 !important;
}

.Search-page__facets-item-child > div > div button:last-of-type svg {
  fill: #404040 !important;
}

.Search-page__facets-item-child > div > div > div {
  padding-right: 30px;
}

.Search-page__facets-item-child > div > div > svg {
  position: absolute !important;
  top: -2px !important;
  right: 25px;
  left: initial !important;
  width: 18px !important;
  height: 18px !important;
}

.Search-page__facets-item-child.checkbox {
  padding-left: 25px !important;
}

.Search-page__facets-item-child.checkbox input {
  margin-left: 20px;
}

.Search-page__facets-item-child.checkbox > div > div > div {
  left: -25px !important;
}

.Search-page__facets-item-child.checkbox div div svg {
  fill: #7c878e !important;
  transform: initial !important;
}

.Search-page__facets-item-child.selected {
  font-weight: 600 !important;
}

.Search-page__facets-item-child.selected svg {
  transform: initial !important;
  opacity: 1 !important;
}

.Search-page__facets-show-more-button {
  text-align: center !important;
  border: solid 1px #bfd0da !important;
  border-radius: 3px;
  margin-top: 10px !important;
  margin-bottom: 18px !important;
  width: 100%;
  background-color: #fff !important;
}

.Search-page__facets-show-more-button:hover {
  background-color: #f5f8f9 !important;
}

.Search-page__facets-show-more-button span {
  color: #33383b !important;
  display: block;
}

.Search-page__facets-dialog {
  z-index: 200000 !important;
}

.Search-page__facets-dialog h3 {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 10px 20px 15px !important;
}

@media (min-width: 768px) {
  .Search-page__facets-dialog h3 {
    padding: 10px 45px 15px !important;
  }
}

.Search-page__facets-dialog a {
  font-size: 12px !important;
  color: #000 !important;
  line-height: 28px;
  display: block;
}

@media (max-width: 767px) {
  .Search-page__facets-dialog > div > div {
    width: 90% !important;
  }
}

.Search-page__facets-dialog div > div > div {
  max-width: auto !important;
}

.Search-page__facets-dialog div > div > div > div {
  padding: 0px 20px 24px !important;
  column-count: 3;
}

.Search-page__facets-dialog div > div > div > div:last-child {
  column-count: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .Search-page__facets-dialog div > div > div > div {
    column-count: 1;
    overflow-y: scroll !important;
    z-index: 200000 !important;
  }
}

@media (min-width: 768px) {
  .Search-page__facets-dialog div > div > div > div {
    padding: 0px 45px 24px !important;
    display: inline-block;
  }
}

.Search-page__facets-dialog div > div > div > div > div {
  padding: 0 0 10px !important;
  column-count: 1;
  cursor: pointer;
  color: #404040;
  width: 100%;
}

.Search-page__facets-dialog div > div > div > div > div > div {
  padding-bottom: 0 !important;
}

.Search-page__facets-dialog div > div > div > div > div svg {
  fill: #7c878e !important;
  vertical-align: top;
  margin: -3px -30px 0 0;
}

.Search-page__facets-dialog div > div > div > div > div span {
  padding-left: 35px;
  display: inline-block;
}

.Search-page__facets-dialog div > div > div > div > div:last-child {
  text-align: left !important;
}

.Search-page__facets-dialog button {
  background-color: #4c9eff !important;
  color: #fff !important;
  padding: 0 34px !important;
}

.Search-page__facets-dialog button:hover {
  background-color: #3b7bc8 !important;
}

.Search-page__facets .Search-page__filters {
  order: 10;
}

.Search-page__facets .Search-page__filters > div {
  padding: 18px 0;
}

.Search-page__facets .Search-page__filters .Search-page__filters-requests-wrapper {
  margin-left: 0;
}

.Search-page__facets .Search-page__filters .Search-page__filters-typical-wrapper {
  margin-left: 0;
}

.mui-ripple-circle {
  display: none;
}

/* Pipeline Page */

.Pipeline {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;
  scrollbar-width: none;
}

.Pipeline::-webkit-scrollbar {
  display: none;
}

.Pipeline_container {
  position: relative;
}

@media (min-width: 768px) {
  .Pipeline_container {
    margin-top: -15px;
  }
}

.Pipeline_container h3 {
  font-weight: 600;
  display: inline-block;
  padding-right: 45px;
}

.Pipeline_container p {
  display: inline-block;
  margin-top: 20px;
  font-size: 14px;
  color: #7c878e;
}

.Pipeline_container.expanded .Search-page__view {
  position: absolute;
  right: 0;
  top: 10px;
}

.Pipeline_container.collapsed .Search-page__view {
  position: absolute;
  right: 0;
  top: 8px;
}

@media (max-width: 767px) {
  .Pipeline_container.collapsed .Search-page__view {
    top: 6px;
    margin-right: -5px !important;
  }
}

@media (min-width: 768px) {
  .Pipeline_container.collapsed .Search-page__view {
    top: 10px;
    right: 5px;
  }
}

@media (max-width: 767px) {
  .Pipeline_container.collapsed p {
    margin-top: 15px;
  }
}

.Pipeline_container.collapsed p small {
  font-size: 14px !important;
  color: #404040;
}

.Pipeline_container.collapsed .col-md-9 .row {
  position: relative;
}

@media (max-width: 767px) {
  .Pipeline_container.collapsed .col-md-9 .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.Pipeline_container .Search-page__view > a {
  display: inline-block;
  padding: 12px;
  position: relative;
  top: 10px;
  width: 48px;
  height: 48px;
  border: 10px;
  box-sizing: border-box;
  left: 10px;
}

.Pipeline_container .Search-page__view > a svg {
  color: #7c878e !important;
}

.Pipeline__container {
  position: relative;
  padding-top: 40px;
}

@media (max-width: 767px) {
  .Pipeline__container {
    margin-left: 0;
    margin-right: 0;
  }
}

.Pipeline__wrapper {
  flex: 1 1;
  margin-right: 15px;
}

.Pipeline__wrapper:last-child {
  margin-right: 0;
}

.Pipeline__wrapper h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.Pipeline__wrapper small {
  color: #7c878e;
}

.Pipeline__column {
  min-width: 224px;
  min-height: 800px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  margin-top: 15px;
}

.Pipeline__card {
  padding: 10px 25px 20px 15px;
  margin: 5px 0;
  min-width: 175px;
  height: 80px;
  background-color: white;
  font-weight: 600;
  position: relative !important;
}

.Pipeline__card .overlay-noClick {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.Pipeline__card div {
  font-size: 14px;
}

.Pipeline__card a {
  color: #404040;
}

.Pipeline__card.red {
  border-left: solid 5px #d0021b;
}

.Pipeline__card.red .Pipeline__card-deadline {
  color: #d0021b !important;
}

.Pipeline__card.red .Pipeline__card-deadline svg {
  color: #d0021b !important;
}

.Pipeline__card.yellow {
  border-left: solid 5px #ffc72c;
}

.Pipeline__card.yellow .Pipeline__card-deadline {
  color: #ffc72c !important;
}

.Pipeline__card.yellow .Pipeline__card-deadline svg {
  color: #ffc72c !important;
}

.Pipeline__card.green {
  border-left: solid 5px #417505;
}

.Pipeline__card.green .Pipeline__card-deadline {
  color: #417505 !important;
}

.Pipeline__card.green .Pipeline__card-deadline svg {
  color: #417505 !important;
}

.Pipeline__card.gray {
  border-left: solid 5px #bfd0da;
}

.Pipeline__card.gray .Pipeline__card-deadline {
  color: #7c878e !important;
}

.Pipeline__card.gray .Pipeline__card-deadline svg {
  color: #7c878e !important;
}

.Pipeline__card .Full-card__request-size {
  position: absolute;
  bottom: 12px;
  left: 20px;
  font-weight: 400;
}

.Pipeline__card .Full-card__request-size .Full-card__request-size-edit {
  margin: 0 !important;
}

.Pipeline__card .Material-cards-actions-wrapper {
  position: absolute;
  top: 0px;
  right: 5px;
}

.Pipeline__card .Material-cards-actions-wrapper svg {
  color: #7c878e !important;
}

.Pipeline__card-deadline {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px !important;
}

.Pipeline__card-deadline svg {
  width: 20px !important;
  height: 20px !important;
  vertical-align: middle;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .Pipeline-cards__collapsed tbody td:first-child,
  .Pipeline-cards__collapsed thead th:first-child {
    width: 75%;
  }

  .Pipeline-cards__collapsed tbody tr {
    position: relative;
  }

  .Pipeline-cards__collapsed tbody tr .Full-card__request-size.open-true {
    position: absolute;
    margin-top: -15px;
    padding: 0 10px;
    background: white;
    margin-left: -175px;
    z-index: 1;
  }

  .Pipeline-cards__collapsed tbody tr .Full-card__request-size.open-true .Full-card__status {
    padding-right: 10px !important;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(4) {
    overflow: visible !important;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(4) svg {
    margin-right: 0 !important;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(4),
  .Pipeline-cards__collapsed thead th:nth-child(4) {
    width: 22%;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(4) .Material-cards-stages-wrapper,
  .Pipeline-cards__collapsed thead th:nth-child(4) .Material-cards-stages-wrapper {
    float: right;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(2),
  .Pipeline-cards__collapsed thead th:nth-child(2) {
    width: 16%;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(2) .Full-card__request-size-edit,
  .Pipeline-cards__collapsed thead th:nth-child(2) .Full-card__request-size-edit {
    margin: 0 !important;
  }

  .Pipeline-cards__collapsed tbody td:nth-child(2) .Full-card__status,
  .Pipeline-cards__collapsed thead th:nth-child(2) .Full-card__status {
    margin-right: 5px !important;
  }
}

.Pipeline-cards__collapsed .Full-card__status {
  border: 0 !important;
  padding-left: 10px !important;
}

.Pipeline-cards__collapsed .Full-card__status span {
  padding: 0 !important;
}

.Pipeline-cards__collapsed .Material-cards-actions-wrapper {
  float: right;
}

@media (max-width: 767px) {
  .Pipeline-cards__collapsed .Material-cards__expanded-actions {
    position: relative !important;
    top: initial;
  }
}

.Pipeline__filters {
  position: relative;
  margin-top: -17px;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  flex-flow: column;
}

@media screen and (min-width: 1024px) {
  .Pipeline__filters {
    padding-right: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Pipeline__filters {
    padding-right: 20px;
  }
}

.Pipeline__filters a div div {
  padding: 5px !important;
}

.Pipeline__filters a.selected {
  font-weight: 700 !important;
}

.Pipeline__filters .showArchivedState > div > div {
  padding: 10px !important;
}

.Pipeline__filters .showArchivedState.selected {
  font-weight: bold !important;
}

.Pipeline__filters p {
  position: relative;
  margin-bottom: 0;
}

.Pipeline__filters p button {
  min-width: 36px !important;
  position: absolute !important;
  right: 0 !important;
  top: -10px !important;
}

.Pipeline__filters p button span {
  color: #7c878e;
  font-size: 12px !important;
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.Pipeline__filters hr {
  margin: 15px 0;
}

.Pipeline__filters .displayHidden {
  font-weight: bold;
}

.Pipeline__filters small {
  font-weight: 600;
  font-size: 12px !important;
  margin: 5px 0 10px;
}

.Pipeline__filters small span {
  font-weight: normal !important;
}

/* Explorer Pages */

@media (max-width: 767px) {
  .Explorer .profile-page-report-error-container {
    margin-top: -8px;
  }
}

@media (max-width: 480px) {
  .Explorer .profile-page-report-error-container {
    display: none;
  }
}

.Explorer .profile-page-report-error-container .profile-page-report-error-content {
  margin: 0 16px 0 0;
}

.Explorer h1 {
  font-weight: 600;
}

.Explorer h1.ge-title {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-top: -30px;
}

@media (max-width: 480px) {
  .Explorer h1.ge-title {
    margin-top: 6px;
  }
}

.Explorer .ge-dialog-close {
  pointer-events: none;
  position: absolute;
  top: -36px;
  left: 0;
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row-reverse;
  color: #fffaeb;
  filter: drop-shadow(black 0 0 4px);
}

.Explorer .ge-dialog-close svg {
  padding: 6px;
  pointer-events: all;
  cursor: pointer;
}

.Explorer .ge-search-field {
  background: #ffffff;
  border: 1px solid #bfd0da !important;
  border-radius: 3px;
  height: 32px !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.Explorer .ge-autocomplete-results {
  background: #ffffff;
  border: 1px solid #bfd0da !important;
  border-radius: 3px;
}

.Explorer .ge-scroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 0.25rem;
  margin-bottom: 0.25rem;
}

.Explorer .ge-scroll > div {
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .Explorer .ge-scroll > div {
    display: none;
  }
}

.Explorer .ge-pagination {
  margin: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

@media (max-width: 720px) {
  .Explorer .ge-pagination {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
}

@media (max-width: 767px) {
  .Explorer .ge-pagination {
    justify-content: center;
  }
}

.Explorer .ge-pagination span {
  font-size: 0.9rem;
}

.Explorer .ge-pagination .ge-pagination-size {
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.Explorer .ge-pagination .ge-pagination-size select {
  border: none !important;
  outline: none !important;
}

.Explorer .ge-pagination .ge-pagination-size > div {
  margin: unset !important;
}

.Explorer .ge-simple-input-field input {
  padding: 0.5rem !important;
}

.Explorer .ge-simple-select-field {
  background: #ffffff;
  border: 1px solid #bfd0da !important;
  border-radius: 3px;
  height: 32px !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.Explorer .ge-simple-select-field:focus {
  outline-color: #4c9eff !important;
}

.Explorer .ge-focus-container {
  overflow-y: auto;
  max-height: 50vh;
}

.Explorer .ge-focus-sublist {
  padding-left: 20px;
}

@media (max-width: 480px) {
  .Explorer .ge-focus-sublist {
    padding-left: 12px;
  }
}

.Explorer .ge-focus-checkbox.active svg {
  fill: #4c9eff !important;
}

.Explorer .ge-focus-checkbox.MuiCheckbox-indeterminate svg {
  fill: #90c2ff !important;
}

.Explorer .ge-focus-expand {
  min-width: 40px;
  margin-left: 12px;
}

@media (max-width: 480px) {
  .Explorer .ge-focus-expand {
    min-width: 32px;
  }
}

.Explorer.MuiDialog-root .MuiDialog-paper {
  overflow: unset;
}

.Explorer .Material-cards {
  width: unset;
}

.Explorer .Material-cards svg {
  top: 0 !important;
}

.Explorer .MuiTableCell-root {
  font-family: 'Open Sans' !important;
}

.Explorer .MuiTableCell-head {
  padding: 0.75rem 0.3rem 0.6rem 0.9rem !important;
}

.Explorer .Mui-focused fieldset {
  border-color: #4c9eff !important;
}

.Explorer .MuiDialog-paper {
  width: 75%;
  max-width: 720px;
  max-height: 80%;
  padding: 24px;
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 480px) {
  .Explorer .MuiDialog-paper {
    width: 80%;
    padding: 16px;
  }
}

.Explorer.MuiAutocomplete-popper {
  width: 620px !important;
}

@media (max-width: 960px) {
  .Explorer.MuiAutocomplete-popper {
    width: calc(75% - 100px) !important;
  }
}

.Explorer .MuiTableSortLabel-root {
  display: none;
}

.Explorer .MuiTableSortLabel-root.ge-table-sort {
  display: inline-flex;
}

.Explorer.ge-saved-searches .MuiDialog-paper {
  width: unset;
  min-width: 320px;
}

@media (max-width: 480px) {
  .Explorer.ge-saved-searches .MuiDialog-paper {
    min-width: 220px;
  }
}

.Explorer.ge-saved-searches button {
  margin: 4px;
  max-height: 32px;
}

@media (max-width: 480px) {
  .Explorer.ge-saved-searches button {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.Explorer.ge-saved-searches .ge-delete {
  margin-right: 0;
}

@media (max-width: 480px) {
  .Explorer.ge-saved-searches .ge-delete {
    max-width: 24px;
    margin-left: 6px;
  }
}

.Explorer.ge-profile-card .MuiDialog-paper {
  max-width: 768px;
  min-height: 160px;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
}

.Explorer.ge-profile-card .Profile-card {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0;
}

.Explorer.ge-profile-card .Full-card {
  flex-grow: 1;
  margin: 0;
  border-radius: 4px;
  border-left-width: 8px;
}

@media (max-width: 991px) {
  .Explorer.ge-profile-card .Full-card__actions {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .Explorer.ge-profile-card .Full-card__actions {
    display: none;
  }
}

@media (max-width: 991px) {
  .Explorer.ge-profile-card .Full-card__actions .Material-cards-actions-wrapper {
    position: relative;
    top: unset !important;
  }
}

.Explorer.ge-profile-card
  .Full-card__actions
  .Material-cards-actions-wrapper
  .Material-cards__expanded-actions {
  position: relative !important;
  top: unset;
  right: unset;
}

@media (max-width: 991px) {
  .Explorer.ge-profile-card
    .Full-card__actions
    .Material-cards-actions-wrapper
    .Material-cards__expanded-actions {
    padding-left: 8px !important;
  }
}

.Explorer.ge-profile-card .Full-card__notes {
  margin-top: 12px;
  padding-bottom: 12px;
}

@media (max-width: 991px) {
  .Explorer.ge-profile-card .Full-card__notes {
    display: none;
  }
}

.Explorer.ge-profile-card h1 {
  margin-bottom: 2px;
}

.Explorer.ge-profile-card .Full-card__type {
  margin-bottom: 10px;
}

.Explorer.ge-profile-card .button-primary {
  height: 36px !important;
}

.Explorer.ge-charity-card .MuiDialog-paper {
  max-width: 480px;
}

.Explorer .button-primary {
  background-color: #ffc72c !important;
  color: rgba(0, 0, 0, 0.87) !important;
  height: unset !important;
  line-height: unset !important;
  letter-spacing: 0 !important;
  padding: 0.5rem 1.5rem !important;
  box-shadow: none !important;
}

.Explorer .button-primary:hover {
  background-color: #ffd86c !important;
}

.Explorer .button-primary.ge-search-button {
  background-color: #806416 !important;
  color: #ffe9ac !important;
}

.Explorer .button-primary.ge-search-button:hover {
  background-color: #402701 !important;
}

.Explorer .button-link {
  color: #7c878e !important;
  height: unset !important;
  min-width: 88px !important;
  text-transform: unset !important;
}

.Explorer .button-link span {
  font-weight: 400 !important;
}

.Explorer .button-link svg {
  height: 18px !important;
  width: 18px !important;
}

.Explorer .full-text-modal {
  display: inline-block;
}

.Explorer .full-text-modal .show-modal-button {
  background-color: #bfd0da !important;
}

.Explorer .ge-button {
  height: 32px !important;
  line-height: unset !important;
  padding: 0.25rem !important;
  padding-right: 0.35rem !important;
  color: #404040 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  min-width: 60px !important;
}

.Explorer .ge-button.grey {
  background-color: #7c878e !important;
  color: #ffffff !important;
}

.Explorer .ge-button.grey:disabled {
  background-color: #9badb7 !important;
}

.Explorer .ge-button.grey:hover {
  background-color: #9badb7 !important;
  color: #ffffff !important;
}

.Explorer .ge-button.dark-grey {
  background-color: #404040 !important;
  color: #ffffff !important;
}

.Explorer .ge-button.dark-grey:hover {
  background-color: #666666 !important;
}

.Explorer .ge-header-button {
  height: 26px !important;
  min-width: unset !important;
  line-height: unset !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  color: #404040 !important;
  border: 1px solid #ffc72c !important;
  background-color: #fff4d6 !important;
  text-transform: unset !important;
  letter-spacing: 0 !important;
}

.Explorer .ge-header-button:hover {
  background-color: #fffaeb !important;
}

.Explorer .ge-header-button span {
  font-weight: 600 !important;
}

.Explorer .ge-header-button.active {
  background-color: #ffc72c !important;
}

.Explorer .ge-header-button-details {
  margin-right: 1rem !important;
}

.Explorer .ge-button svg,
.Explorer .ge-header-button svg {
  height: 16px !important;
  width: 16px !important;
  color: inherit !important;
}

.Explorer .ge-result-button {
  border: 1px solid #bfd0da !important;
}

.Explorer .ge-result-button svg {
  height: 16px !important;
  width: 16px !important;
}

.Explorer .ge-result-button span {
  font-weight: 400 !important;
}

.Explorer .ge-result-button .ge-icon-button {
  padding: 0 !important;
  height: 16px !important;
  width: 16px !important;
}

.Explorer .ge-table-cell {
  display: inline;
}

.Explorer .ge-table-cell .ge-icon-button,
.Explorer .ge-icon-button svg {
  padding: 0 !important;
  height: 16px !important;
  width: 16px !important;
}

.Explorer .ge-table-cell .ge-icon-button {
  background-color: #d9e3e9 !important;
}

.Explorer .ge-table-cell .ge-pipeline-button {
  background-color: #417505 !important;
  margin-left: 3px !important;
}

.Explorer .ge-table-cell .ge-pipeline-button svg {
  fill: #ffffff !important;
}

.Explorer.ge-dialog > div > div > div > div {
  display: flex;
}

.Explorer .ge-graph-no-data {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
}

@keyframes spin-reverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.spin-reverse {
  animation: spin-reverse 1.5s linear infinite;
}

/* Contact Page */

.Contact {
  position: relative;
  padding: 0;
  height: calc(93.575vh - 20px);
  overflow: hidden;
}

.Contact .frame {
  padding: 0;
  width: 100%;
  height: calc(93.25vh - 20px);
  border: 0;
}

.Contact .footer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  background-color: #f5f8f9;
}

/* Login Page */

.login,
.frlogin {
  background-color: #fff;
}

@media (max-width: 767px) {
  .login,
  .frlogin {
    height: 100vh;
  }
}

.login .container,
.frlogin .container {
  padding: 0;
  max-width: 100%;
  width: 100%;
}

.login .container .Header-button,
.frlogin .container .Header-button {
  position: absolute !important;
  right: 0;
  top: 10px;
}

@media (max-width: 767px) {
  .login .container,
  .frlogin .container {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .login .row,
  .frlogin .row {
    height: 100vh;
  }
}

.login .row .col-md-9,
.frlogin .row .col-md-9 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 100vw;
  flex-basis: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .login .row .col-md-9,
  .frlogin .row .col-md-9 {
    width: auto;
    margin-left: 10%;
    justify-content: flex-start;
  }
}

.login .row .col-xs-12,
.frlogin .row .col-xs-12 {
  position: relative;
  padding: 15px 30px;
}

@media (max-width: 767px) {
  .login .row .col-xs-12,
  .frlogin .row .col-xs-12 {
    background-image: none !important;
  }
}

.login h2,
.frlogin h2 {
  font-size: 28px !important;
  color: #fff;
  text-align: center;
}

.login h3,
.frlogin h3 {
  font-size: 21px !important;
  font-weight: 600 !important;
}

@media (max-width: 767px) {
  .login h3,
  .frlogin h3 {
    text-align: center;
  }
}

.login p,
.frlogin p {
  font-size: 14px !important;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .login__left,
  .login__imagine-logo,
  .frlogin__left,
  .frlogin__imagine-logo {
    display: none;
  }

  .login__imagine-logo-mobile,
  .frlogin__imagine-logo-mobile {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 768px) {
  .login__imagine-logo-mobile,
  .frlogin__imagine-logo-mobile {
    display: none;
  }
}

.login form.Login > div,
.frlogin form.Login > div {
  display: block !important;
  width: 100% !important;
  height: 58px !important;
}

.login form.Login label,
.frlogin form.Login label {
  font-size: 12px !important;
  color: #7c878e !important;
  top: 15px !important;
}

.login form.Login input,
.frlogin form.Login input {
  border-radius: 3px;
  width: 460px !important;
  height: 47px !important;
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .login form.Login input,
  .frlogin form.Login input {
    width: 100% !important;
  }
}

.login form.Login button,
.frlogin form.Login button {
  background-color: #ffc72c !important;
  display: block !important;
  height: 48px !important;
  width: 100%;
  margin-top: 15px !important;
}

.login form.Login button:hover,
.login form.Login button:focus,
.frlogin form.Login button:hover,
.frlogin form.Login button:focus {
  background-color: #ffd86c !important;
}

.login .reset-password-link,
.frlogin .reset-password-link {
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
}

.login .password_reset_dialog .message-danger,
.frlogin .password_reset_dialog .message-danger {
  padding-top: 20px;
  color: #d0021b;
}

.login .password_reset_dialog .message-success,
.frlogin .password_reset_dialog .message-success {
  color: #417505;
}

.Reset-password-page__facets-dialog .Login > * {
  margin-bottom: 10px;
}

.Reset-password-page__facets-dialog button.reset {
  background-color: #4c9eff !important;
  color: #fff !important;
  padding: 0 34px !important;
}

.Reset-password-page__facets-dialog button.reset:hover {
  background-color: #3b7bc8 !important;
}

.Reset-password-page__facets-dialog button.reset:disabled {
  background-color: #7c878e !important;
}

.reset-password-form > * {
  display: block !important;
}

.reset-password-form button {
  background-color: #4c9eff !important;
  color: #fff !important;
  padding: 0 34px !important;
  margin-top: 20px !important;
}

.reset-password-form button:hover {
  background-color: #3b7bc8 !important;
}

.login__left {
  width: 100%;
}

.login__left h2 {
  padding: 0 20px;
}

.login__left .paragraph {
  padding-right: 50px;
  padding-left: 50px;
}

.login--left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

/* purgecss end ignore */

